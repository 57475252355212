import React, { Fragment } from "react";

const Habeas = () => {
  return (
    <Fragment>
      <ol>
        <li>
          <strong>
            NORMATIVIDAD LEGAL Y &Aacute;MBITO DE APLICACI&Oacute;N:{" "}
          </strong>
        </li>
      </ol>
      <p>
        La presente pol&iacute;tica de tratamiento de datos personales es
        elaborada de conformidad con lo dispuesto en la Constituci&oacute;n
        Pol&iacute;tica, la ley 1581 de 2012, el Decreto Reglamentario 1377 de
        2013 y dem&aacute;s disposiciones complementarias y ser&aacute; aplicada
        por NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N. Respecto de la
        recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n,
        supresi&oacute;n y de todas aquellas actividades que constituyan
        tratamiento de datos personales.
      </p>
      <ol start={2}>
        <li>
          <strong>DEFINICIONES:</strong>
        </li>
      </ol>
      <p>
        Para efectos de la ejecuci&oacute;n de la presente pol&iacute;tica y de
        conformidad con la normatividad legal, ser&aacute;n aplicables las
        siguientes definiciones:
      </p>
      <ol>
        <li>
          <strong>Autorizaci&oacute;n: </strong>
        </li>
      </ol>
      <p>
        Consentimiento previo<strong>,</strong> expreso e informado del Titular
        para llevar a cabo el Tratamiento de datos personales.
      </p>
      <ol>
        <li>
          <strong>Aviso de privacidad:</strong>
        </li>
      </ol>
      <p>
        Documento f&iacute;sico, electr&oacute;nico o en cualquier otro formato
        generado por el responsable que se pone a disposici&oacute;n del titular
        para el tratamiento de sus datos personales. En el aviso de privacidad
        se comunica al titular le informaci&oacute;n relativa a la existencia de
        las pol&iacute;ticas de tratamiento de informaci&oacute;n que le
        ser&aacute;n aplicables, la forma de acceder a las mismas y la
        finalizaci&oacute;n del tratamiento que se pretende dar a los datos
        personales.
      </p>
      <ol>
        <li>
          <strong>Base de Datos:</strong>
        </li>
      </ol>
      <p>
        Conjunto organizado de datos personales que sea objeto de tratamiento
      </p>
      <ol>
        <li>
          <strong>Dato Personal:</strong>
        </li>
      </ol>
      <p>
        Cualquier informaci&oacute;n vinculada o que pueda asociarse a una o
        varis personas naturales determinadas o determinables.
      </p>
      <ol>
        <li>
          <strong>Dato P&uacute;blico:</strong>
        </li>
      </ol>
      <p>
        Es el dato calificado como tal seg&uacute;n los mandatos de la ley o de
        la Constituci&oacute;n Pol&iacute;tica y aquel que no sea semiprivado,
        privado o sensible. Son p&uacute;blicos, entre otros, los datos
        relativos al estado civil de las personas, a su profesi&oacute;n u
        oficio, a su calidad de comerciante o de servidor p&uacute;blico y
        aquellos que puedan obtenerse sin reserva alguna. Por su naturaleza, los
        datos p&uacute;blicos pueden estar contenidos, entre otros, en registros
        p&uacute;blicos, documentos p&uacute;blicos, gacetas y boletines
        oficiales.
      </p>
      <ol>
        <li>
          <strong>Dato privado:</strong>
        </li>
      </ol>
      <p>
        Es el dato que por su naturaleza &iacute;ntima o reservada s&oacute;lo
        es relevante para el titular.
      </p>
      <ol>
        <li>
          <strong>Datos sensibles:</strong>
        </li>
      </ol>
      <p>
        Se entiende por datos sensibles aquellos que afectan la intimidad del
        Titular o cuyo uso indebido puede generar su discriminaci&oacute;n,
        tales como aquellos que revelen el origen racial o &eacute;tnico, la
        orientaci&oacute;n pol&iacute;tica, las convicciones religiosas o
        filos&oacute;ficas, la pertenencia a sindicatos, organizaciones
        sociales, de derechos humanos o que promueva intereses de cualquier
        partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as
        de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los
        datos relativos a la salud, a la vida sexual y los datos
        biom&eacute;tricos.
      </p>
      <ol>
        <li>
          <strong>Encargado del Tratamiento:</strong>
        </li>
      </ol>
      <p>
        Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por
        s&iacute; misma o en asocio con otros, realice el Tratamiento de datos
        personales por cuenta del Responsable del Tratamiento
      </p>
      <ol>
        <li>
          <strong>Responsable del Tratamiento:</strong>
        </li>
      </ol>
      <p>
        Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por
        s&iacute; misma o en asocio con otros, decida sobre la base de datos y/o
        el Tratamiento de los datos.
      </p>
      <ol>
        <li>
          <strong>Titular:</strong>
        </li>
      </ol>
      <p>Persona natural cuyos datos personales sean objeto de Tratamiento</p>
      <ol>
        <li>
          <strong>Tratamiento:</strong>
        </li>
      </ol>
      <p>
        Cualquier operaci&oacute;n o conjunto de operaciones sobre datos
        personales, tales como la recolecci&oacute;n, almacenamiento, uso,
        circulaci&oacute;n o supresi&oacute;n de los mismos.
      </p>
      <ol start={3}>
        <li>
          <strong>
            FINALIDAD CON LA QUE SE EFECT&Uacute;A LA RECOLECCI&Oacute;N DE
            DATOS PERSONALES Y TRATAMIENTO DE LOS MISMOS:
          </strong>
        </li>
      </ol>
      <p>
        LA NOTARIA13 DEL CIRCULO DE MEDELL&Iacute;N podr&aacute; hacer uso de
        los datos personales para:
      </p>
      <p>
        <strong>a).</strong>Ejecutar la relaci&oacute;n contractual existente
        con sus clientes, proveedores y trabajadores, incluida el pago de
        obligaciones contractuales;
      </p>
      <ol>
        <li>
          <strong>b) </strong>Proveer los servicios y/o los productos requeridos
          por sus usuarios;
        </li>
        <li>
          <strong>c) </strong>Informar sobre nuevos productos o servicios y/o
          sobre cambios en los mismos;
        </li>
      </ol>
      <p>
        <strong>d).</strong>Evaluar la calidad del servicio;
      </p>
      <p>
        <strong>e).</strong>Realizar estudios internos sobre h&aacute;bitos de
        consumo;
      </p>
      <p>
        <strong>f).</strong>Enviar al correo f&iacute;sico, electr&oacute;nico,
        celular o dispositivo m&oacute;vil, v&iacute;a mensajes de texto (SMS
        y/o MMS) o a trav&eacute;s de cualquier otro medio an&aacute;logo y/o
        digital de comunicaci&oacute;n creado o por crearse, informaci&oacute;n
        comercial, publicitaria o promocional sobre los productos y/o servicios,
        eventos y/o promociones de tipo comercial o no de estas, con el fin de
        impulsar, invitar, dirigir, ejecutar, informar y de manera general,
        llevar a cabo campa&ntilde;as, promociones o concursos de
        car&aacute;cter comercial o publicitario, adelantados por LA NOTARIA 13
        DE MEDELL&Iacute;N y/o por terceras personas;
      </p>
      <p>
        <strong>g).</strong>Desarrollar el proceso de selecci&oacute;n,
        evaluaci&oacute;n, y vinculaci&oacute;n laboral;
      </p>
      <p>
        <strong>h).</strong>Soportar procesos de auditor&iacute;a interna o
        externa;
      </p>
      <p>
        <strong>i).</strong>Registrar la informaci&oacute;n de empleados y/o
        pensionados (activos e inactivos) en las bases de datos de LA NOTARIA 13
        DEL CIRCULO DE MEDELL&Iacute;N Los indicados en la autorizaci&oacute;n
        otorgada por el titular del dato o descritos en el aviso de privacidad
        respectivo, seg&uacute;n sea el caso;
      </p>
      <p>
        <strong>j).</strong>Suministrar, compartir, enviar o entregar sus datos
        personales a empresas filiales, vinculadas, o subordinadas de LA NOTARIA
        13 DEL CIRCULO DE MEDELL&Iacute;N ubicadas en Colombia o cualquier otro
        pa&iacute;s en el evento que dichas compa&ntilde;&iacute;as requieran la
        informaci&oacute;n para los fines aqu&iacute; indicados.
      </p>
      <p>
        Respecto de los datos (i) recolectados directamente en los puntos de
        seguridad, (ii) tomados de los documentos que suministran las personas
        al personal de seguridad y (iii) obtenidos de las videograbaciones que
        se realizan dentro o fuera de las instalaciones de LA NOTARIA 13 DEL
        CIRCULO DE MEDELL&Iacute;N, &eacute;stos se utilizar&aacute;n para fines
        de seguridad de las personas, los bienes e instalaciones de LA NOTARIA
        13 DEL CIRCULO DE MEDELL&Iacute;N y podr&aacute;n ser utilizados como
        prueba en cualquier tipo de proceso.
      </p>
      <p>
        Si un dato personal es proporcionado, dicha informaci&oacute;n
        ser&aacute; utilizada s&oacute;lo para los prop&oacute;sitos aqu&iacute;
        se&ntilde;alados, y por tanto, LA NOTARIA 13 DEL CIRCULO DE
        MEDELL&Iacute;N no proceder&aacute; a vender, licenciar, transmitir, o
        divulgar la misma, salvo que: (i) exista autorizaci&oacute;n expresa
        para hacerlo; (ii) sea necesario para permitir a los contratistas o
        agentes prestar los servicios encomendados; (iii) sea necesario con el
        fin de proveer nuestros servicios y/o productos; (iv) sea necesario
        divulgarla a las entidades que prestan servicios de mercadeo en nombre
        de LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N o a otras entidades con
        las cuales se tengan acuerdos de mercado conjunto; (v) la
        informaci&oacute;n tenga relaci&oacute;n con una fusi&oacute;n,
        consolidaci&oacute;n, adquisici&oacute;n, desinversi&oacute;n, u otro
        proceso de restructuraci&oacute;n de la sociedad; (vi) que sea requerido
        o permitido por la ley.
      </p>
      <p>
        LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N podr&aacute; subcontratar a
        terceros para el procesamiento de determinadas funciones o
        informaci&oacute;n. Cuando efectivamente se subcontrate con terceros el
        procesamiento de informaci&oacute;n personal o se proporcione
        informaci&oacute;n personal a terceros prestadores de servicios, LA
        NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N advierte a dichos terceros
        sobre la necesidad de proteger dicha informaci&oacute;n personal con
        medidas de seguridad apropiadas, se proh&iacute;be el uso de la
        informaci&oacute;n para fines propios y se solicita que no se divulgue
        la informaci&oacute;n personal a otros.
      </p>
      <ol start={4}>
        <li>
          <strong>
            PRINCIPIOS APLICABLES AL TRATAMIENTO DE DATOS PERSONALES:
          </strong>
        </li>
      </ol>
      <p>
        El tratamiento de datos personales en LA NOTARIA 13 DEL CIRCULO DE
        MEDELL&Iacute;N se regir&aacute; por los siguientes principios:
      </p>
      <ol>
        <li>
          <strong>a) Principio de finalidad: </strong>El Tratamiento de los
          datos personales recogidos debe obedecer a una finalidad
          leg&iacute;tima, la cual debe ser informada al Titular.
        </li>
        <li>
          <strong>b) Principio de libertad: </strong>El Tratamiento s&oacute;lo
          puede llevarse a cabo con el consentimiento, previo, expreso e
          informado del Titular. Los datos personales no podr&aacute;n ser
          obtenidos o divulgados sin previa autorizaci&oacute;n, o en ausencia
          de mandato legal o judicial que releve el consentimiento.
        </li>
        <li>
          <strong>c) Principio de veracidad o calidad: </strong>La
          informaci&oacute;n sujeta a Tratamiento debe ser veraz, completa,
          exacta, actualizada, comprobable y comprensible. No ser&aacute;
          efectuado el tratamiento de datos parciales, incompletos, fraccionados
          o que induzcan a error.
        </li>
        <li>
          <strong>d) Principio de transparencia: </strong>En el Tratamiento debe
          garantizarse el derecho del Titular a obtener de LA NOTARIA 13 DEL
          CIRCULO DE MEDELL&Iacute;N en cualquier momento y sin restricciones,
          informaci&oacute;n acerca de la existencia de datos que le conciernan.
        </li>
        <li>
          <strong>
            e) Principio de acceso y circulaci&oacute;n restringida:{" "}
          </strong>
          El Tratamiento se sujeta a los l&iacute;mites que se derivan de la
          naturaleza de los datos personales, de las disposiciones de la
          presente ley y la Constituci&oacute;n. Los datos personales, salvo la
          informaci&oacute;n p&uacute;blica, y lo dispuesto en la
          autorizaci&oacute;n otorgada por el titular del dato, no podr&aacute;n
          estar disponibles en Internet u otros medios de divulgaci&oacute;n o
          comunicaci&oacute;n masiva, salvo que el acceso sea
          t&eacute;cnicamente controlable para brindar un conocimiento
          restringido s&oacute;lo a los Titulares o terceros autorizados.
        </li>
        <li>
          <strong>f) Principio de seguridad: </strong>La informaci&oacute;n
          sujeta a Tratamiento por parte de LA NOTARIA 13 DEL CIRCULO DE
          MEDELL&Iacute;N se deber&aacute; proteger mediante el uso de las
          medidas t&eacute;cnicas, humanas y administrativas que sean necesarias
          para otorgar seguridad a los registros evitando su
          adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no
          autorizado o fraudulento.
        </li>
        <li>
          <strong>g) Principio de confidencialidad: </strong>Todas las personas
          que intervengan en el tratamiento de datos personales est&aacute;n
          obligadas a garantizar la reserva de la informaci&oacute;n, inclusive
          despu&eacute;s de finalizada su relaci&oacute;n con alguna de las
          labores que comprende el Tratamiento.
        </li>
      </ol>
      <p>
        <strong>PAR&Aacute;GRAFO PRIMERO: </strong>En el evento que se
        recolecten datos personales sensibles, el Titular podr&aacute; negarse a
        autorizar su Tratamiento.
      </p>
      <ol start={5}>
        <li>
          <strong>
            DERECHOS DE LOS TITULARES DE DATOS PERSONALES OBJETO DE TRATAMIENTO
            POR PARTE DE LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N:{" "}
          </strong>
        </li>
      </ol>
      <p>
        Los titulares de datos personales por s&iacute; o por intermedio de su
        representante y/o apoderado o su causahabiente podr&aacute;n ejercer los
        siguientes derechos, respecto de los datos personales que sean objeto de
        tratamiento por parte de LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N:
      </p>
      <ol>
        <li>
          <strong>Derecho de acceso: </strong>
        </li>
      </ol>
      <p>
        En virtud del cual podr&aacute; acceder a los datos personales que
        est&eacute;n bajo el control de LA NOTARIA 13 DEL CIRCULO DE
        MEDELL&Iacute;N, para efectos de consultarlos de manera gratuita al
        menos una vez cada mes calendario, y cada vez que existan modificaciones
        sustanciales de las Pol&iacute;ticas de Tratamiento de la
        informaci&oacute;n que motiven nuevas consultas.
      </p>
      <ol>
        <li>
          <strong>
            Derecho de actualizaci&oacute;n, rectificaci&oacute;n y
            supresi&oacute;n:
          </strong>
        </li>
      </ol>
      <p>
        En virtud del cual podr&aacute; solicitar la actualizaci&oacute;n,
        rectificaci&oacute;n y/o supresi&oacute;n de los datos personales objeto
        de tratamiento, de tal manera que se satisfagan los prop&oacute;sitos
        del tratamiento.
      </p>
      <ol>
        <li>
          <strong>
            Derecho a solicitar prueba de la autorizaci&oacute;n:{" "}
          </strong>
        </li>
      </ol>
      <p>
        Salvo en los eventos en los cuales, seg&uacute;n las normas legales
        vigentes, no se requiera de la autorizaci&oacute;n para realizar el
        tratamiento.
      </p>
      <ol>
        <li>
          <strong>
            Derecho a ser informado respecto del uso del dato personal.
          </strong>
        </li>
      </ol>
      <ol>
        <li>
          <strong>
            Derecho a presentar quejas ante la Superintendencia de Industria y
            Comercio
          </strong>
          :
        </li>
      </ol>
      <p>
        Por infracciones a lo dispuesto en la normatividad vigente sobre
        tratamiento de datos personales.
      </p>
      <ol>
        <li>
          <strong>
            Derecho a requerir el cumplimiento de las &oacute;rdenes emitidas
            por la Superintendencia de Industria y Comercio.
          </strong>
        </li>
      </ol>
      <p>
        <strong>PAR&Aacute;GRAFO PRIMERO: </strong>Para efectos del ejercicio de
        los derechos antes descritos tanto el titular como la persona que lo
        represente deber&aacute; demostrar su identidad y, de ser el caso, la
        calidad en virtud de la cual representa al titular.
      </p>
      <p>
        <strong>PAR&Aacute;GRAFO SEGUNDO: </strong>Los derechos de los menores
        de edad ser&aacute;n ejercidos por medio de las personas que
        est&eacute;n facultadas para representarlos.
      </p>
      <ol start={6}>
        <li>
          <strong>
            DEBERES DE LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N
          </strong>
          <strong>:</strong>
        </li>
      </ol>
      <p>
        Todos los obligados a cumplir esta pol&iacute;tica deben tener presente
        que LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N est&aacute; obligada a
        cumplir los deberes que al respecto imponga la ley. En consecuencia se
        deben cumplir las siguientes obligaciones:
      </p>
      <ol>
        <li>
          <strong> Deberes cuando act&uacute;a como responsable:</strong>
        </li>
      </ol>
      <ul>
        <li>
          Solicitar y conservar, en las condiciones previstas en esta
          pol&iacute;tica, copia de la respectiva autorizaci&oacute;n otorgada
          por el titular.
        </li>
        <li>
          Informar de manera clara y suficiente al titular sobre la finalidad de
          la recolecci&oacute;n y los derechos que le asisten por virtud de la
          autorizaci&oacute;n otorgada.
        </li>
        <li>
          Informar a solicitud del titular sobre el uso dado a sus datos
          personales.
        </li>
        <li>
          Tramitar las consultas y reclamos formulados en los t&eacute;rminos
          se&ntilde;alados en la presente pol&iacute;tica.
        </li>
        <li>
          Procurar que los principios de veracidad, calidad, seguridad y
          confidencialidad en los t&eacute;rminos establecidos en la siguiente
          pol&iacute;tica.
        </li>
        <li>
          Conservar la informaci&oacute;n bajo las condiciones de seguridad
          necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida,
          consulta, uso o acceso no autorizado o fraudulento.
        </li>
        <li>Actualizar la informaci&oacute;n cuando sea necesario.</li>
        <li>Rectificar los datos personales cuando ello sea procedente.</li>
      </ul>
      <ol>
        <li>
          <strong>
            {" "}
            Deberes cuando obra como encargado del tratamiento de datos
            personales:
          </strong>
        </li>
      </ol>
      <p>
        Si realiza el tratamiento de datos en nombre de otra entidad u
        organizaci&oacute;n (Responsable del tratamiento) deber&aacute; cumplir
        los siguientes deberes.
      </p>
      <ul>
        <li>
          Establecer que el Responsable del tratamiento est&eacute; autorizado
          para suministrar los datos personales que tratar&aacute; como
          Encargado.
        </li>
        <li>
          Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio
          del derecho de h&aacute;beas data.
        </li>
        <li>
          Conservar la informaci&oacute;n bajo las condiciones de seguridad
          necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida,
          consulta, uso o acceso no autorizado o fraudulento.
        </li>
        <li>
          Realizar oportunamente la actualizaci&oacute;n, rectificaci&oacute;n o
          supresi&oacute;n de los datos.
        </li>
        <li>
          Actualizar la informaci&oacute;n reportada por los Responsables del
          tratamiento dentro de los cinco (5) d&iacute;as h&aacute;biles
          contados a partir de su recibo.
        </li>
        <li>
          Tramitar las consultas y los reclamos formulados por los titulares en
          los t&eacute;rminos se&ntilde;alados en la presente pol&iacute;tica.
        </li>
        <li>
          Registrar en la base de datos la leyenda &ldquo;reclamo en
          tr&aacute;mite&rdquo; en la forma en que se establece en la presente
          pol&iacute;tica.
        </li>
        <li>
          Insertar en la base de datos la leyenda &ldquo;informaci&oacute;n en
          discusi&oacute;n judicial&rdquo; una vez notificado por parte de la
          autoridad competente sobre procesos judiciales relacionados con la
          calidad del dato personal.
        </li>
        <li>
          Abstenerse de circular informaci&oacute;n que est&eacute; siendo
          controvertida por el titular y cuyo bloqueo haya sido ordenado por la
          Superintendencia de Industria y Comercio.
        </li>
        <li>
          Permitir el acceso a la informaci&oacute;n &uacute;nicamente a las
          personas autorizadas por el titular o facultadas por la ley para dicho
          efecto.
        </li>
        <li>
          Informar a la Superintendencia de Industria y Comercio cuando se
          presenten violaciones a los c&oacute;digos de seguridad y existan
          riesgos en la administraci&oacute;n de la informaci&oacute;n de los
          titulares.
        </li>
        <li>
          Cumplir las instrucciones y requerimientos que imparta la
          Superintendencia de Industria y Comercio.
        </li>
      </ul>
      <ol>
        <li>
          <strong>
            {" "}
            Deberes cuando realiza el tratamiento a trav&eacute;s de un
            encargado:
          </strong>
        </li>
      </ol>
      <ul>
        <li>
          Suministrar al Encargado del tratamiento &uacute;nicamente los datos
          personales cuyo tratamiento est&eacute; previamente autorizado. Para
          efectos de la transmisi&oacute;n nacional o internacional de los datos
          se deber&aacute; suscribir un contrato de transmisi&oacute;n de datos
          personales o pactar cl&aacute;usulas contractuales seg&uacute;n lo
          establecido en el art&iacute;culo 25 del decreto 1377 de 2013.
        </li>
        <li>
          Garantizar que la informaci&oacute;n que se suministre al Encargado
          del tratamiento sea veraz, completa, exacta, actualizada, comprobable
          y comprensible.
        </li>
        <li>
          Comunicar de forma oportuna al Encargado del tratamiento todas las
          novedades respecto de los datos que previamente le haya suministrado y
          adoptar las dem&aacute;s medidas necesarias para que la
          informaci&oacute;n suministrada a este se mantenga actualizada.
        </li>
        <li>
          Informar de manera oportuna al Encargado del tratamiento las
          rectificaciones realizadas sobre los datos personales para que
          &eacute;ste proceda a realizar los ajustes pertinentes.
        </li>
        <li>
          Exigir al Encargado del tratamiento, en todo momento, el respeto a las
          condiciones de seguridad y privacidad de la informaci&oacute;n del
          titular.
        </li>
        <li>
          Informar al Encargado del tratamiento cuando determinada
          informaci&oacute;n se encuentre en discusi&oacute;n por parte del
          titular, una vez se haya presentado la reclamaci&oacute;n y no haya
          finalizado el tr&aacute;mite respectivo.
        </li>
      </ul>
      <ol>
        <li>
          <strong>
            {" "}
            Deberes respecto de la Superintendencia de Industria y Comercio:
          </strong>
        </li>
      </ol>
      <ul>
        <li>
          Informarle las eventuales violaciones a los c&oacute;digos de
          seguridad y la existencia de riesgos en la administraci&oacute;n de la
          informaci&oacute;n de los titulares.
        </li>
        <li>
          Cumplir las instrucciones y requerimientos que imparta la
          Superintendencia de Industria y Comercio.
        </li>
      </ul>
      <ol start={7}>
        <li>
          <strong>
            SOLICITUD DE AUTORIZACI&Oacute;N AL TITULAR DEL DATO PERSONAL:{" "}
          </strong>
        </li>
      </ol>
      <p>
        Con antelaci&oacute;n y/o al momento de efectuar la recolecci&oacute;n
        del dato personal, LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N
        solicitar&aacute; al titular del dato su autorizaci&oacute;n para
        efectuar su recolecci&oacute;n y tratamiento, indicando la finalidad
        para la cual se solicita el dato, utilizando para esos efectos medios
        t&eacute;cnicos automatizados, escritos u orales, que permitan conservar
        prueba de la autorizaci&oacute;n y/o de la conducta inequ&iacute;voca
        descrita en el art&iacute;culo
      </p>
      <p>
        7 del Decreto 1377 de 2013. Dicha autorizaci&oacute;n se
        solicitar&aacute; por el tiempo que sea razonable y necesario para
        satisfacer las necesidades que dieron origen a la solicitud del dato y,
        en todo caso, con observancia de las disposiciones legales que rigen
        sobre la materia.
      </p>
      <ol start={8}>
        <li>
          <strong>AVISO DE PRIVACIDAD: </strong>
        </li>
      </ol>
      <p>
        En el evento en el que LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N no
        pueda poner a disposici&oacute;n del titular del dato personal la
        presente pol&iacute;tica de tratamiento de la informaci&oacute;n,
        publicar&aacute; el aviso de privacidad que se adjunta al presente
        documento, cuyo texto conservar&aacute; para consulta posterior por
        parte del titular del dato y/o de la Superintendencia de Industria y
        Comercio.
      </p>
      <ol start={9}>
        <li>
          <strong>
            LIMITACIONES TEMPORALES AL TRATAMIENTO DE LOS DATOS PERSONALES:
          </strong>
        </li>
      </ol>
      <p>
        LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N solo podr&aacute;
        recolectar, almacenar, usar o circular los datos personales durante el
        tiempo que sea razonable y necesario, de acuerdo con las finalidades que
        justificaron el tratamiento, atendiendo a las disposiciones aplicables a
        la materia de que se trate y a los aspectos administrativos, contables,
        fiscales, jur&iacute;dicos e hist&oacute;ricos de la informaci&oacute;n.
        Una vez cumplida la o las finalidades del tratamiento y sin perjuicio de
        normas legales que dispongan lo contrario, proceder&aacute; a la
        supresi&oacute;n de los datos personales en su posesi&oacute;n. No
        obstante lo anterior, los datos personales deber&aacute;n ser
        conservados cuando as&iacute; se requiera para el cumplimiento de una
        obligaci&oacute;n legal o contractual.
      </p>
      <ol start={10}>
        <li>
          <strong>
            &Aacute;REA RESPONSABLE Y PROCEDIMIENTO PARA EL EJERCICIO DE LOS
            DERECHOS DE LOS TITULARES DEL DATO PERSONAL:{" "}
          </strong>
        </li>
      </ol>
      <p>
        LA ALTA DIRECCI&Oacute;N DE LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N
        ser&aacute; la responsable de atender las peticiones, quejas y reclamos
        que formule el titular del dato en ejercicio de los derechos
        contemplados en el numeral 5 de la presente pol&iacute;tica, a
        excepci&oacute;n del descrito en su literal e). Para tales efectos, el
        titular del dato personal o quien ejerza su representaci&oacute;n
        podr&aacute; enviar su petici&oacute;n, queja o reclamo de lunes a
        viernes de 8:00 A.M a 5:00 P.M al correo electr&oacute;nico{" "}
        <a href="mailto:notaria13@une.net.co">
          <strong>notaria13@une.net.co</strong>
        </a>
        , llamar a la l&iacute;nea telef&oacute;nica de LA NOTARIA 13 DEL
        CIRCULO DE MEDELL&Iacute;N, Medell&iacute;n tel&eacute;fono (4) 4112928,
        o radicarla en las siguiente direcci&oacute;n que correspondientes a
        nuestra oficinas Circular 5&ordm; N # 70-93.
      </p>
      <p>
        La petici&oacute;n, queja o reclamo deber&aacute; contener la
        identificaci&oacute;n del Titular, la descripci&oacute;n de los hechos
        que dan lugar al reclamo, la direcci&oacute;n, y acompa&ntilde;ando los
        documentos que se quiera hacer valer. Si el reclamo resulta incompleto,
        se requerir&aacute; al interesado dentro de los cinco (5) d&iacute;as
        siguientes a la recepci&oacute;n del reclamo para que subsane las
        fallas. Transcurridos dos (2) meses desde la fecha del requerimiento,
        sin que el solicitante presente la informaci&oacute;n requerida, se
        entender&aacute; que ha desistido del reclamo. En caso de que quien
        reciba el reclamo no sea competente para resolverlo, dar&aacute;
        traslado a quien corresponda en un t&eacute;rmino m&aacute;ximo de dos
        (2) d&iacute;as h&aacute;biles e informar&aacute; de la situaci&oacute;n
        al interesado. Una vez recibido el reclamo completo, se incluir&aacute;
        en la base de datos una leyenda que diga &ldquo;reclamo en
        tr&aacute;mite&rdquo; y el motivo del mismo, en un t&eacute;rmino no
        mayor a dos (2) d&iacute;as h&aacute;biles. Dicha leyenda deber&aacute;
        mantenerse hasta que el reclamo sea decidido. El t&eacute;rmino
        m&aacute;ximo para atender el reclamo ser&aacute; de quince (15)
        d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente a
        la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro
        de dicho t&eacute;rmino, se informar&aacute; al interesado los motivos
        de la demora y la fecha en que se atender&aacute; su reclamo, la cual en
        ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as
        h&aacute;biles siguientes al vencimiento del primer t&eacute;rmino.
      </p>
      <ol start={11}>
        <li>
          <strong>
            DATOS RECOLECTADOS ANTES DE LA EXPEDICI&Oacute;N DEL DECRETO 1377 DE
            2013:
          </strong>
        </li>
      </ol>
      <p>
        De conformidad con lo dispuesto en el numeral 3 del art&iacute;culo 10
        del Decreto Reglamentario 1377 de 2013 LA NOTARIA 13 DEL CIRCULO DE
        MEDELL&Iacute;N proceder&aacute; a publicar un aviso en su p&aacute;gina
        web oficial www.notaria13medellin.com dirigido a los titulares de datos
        personales para efectos de dar a conocer la presente pol&iacute;tica de
        tratamiento de informaci&oacute;n y el modo de ejercer sus derechos como
        titulares de datos personales alojados en las bases de datos de LA
        NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N.
      </p>
      <ol start={12}>
        <li>
          <strong>MEDIDAS DE SEGURIDAD:</strong>
        </li>
      </ol>
      <p>
        En desarrollo del principio de seguridad establecido en la Ley 1581 de
        2012, LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N adoptar&aacute; las
        medidas t&eacute;cnicas, humanas y administrativas que sean necesarias
        para otorgar seguridad a los registros evitando su adulteraci&oacute;n,
        p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.
      </p>
      <p>
        El personal que realice el tratamiento de los datos personales
        ejecutar&aacute; los protocolos establecidos con el fin de garantizar la
        seguridad de la informaci&oacute;n.
      </p>
      <ol start={13}>
        <li>
          <strong>FECHA DE ENTRADA EN VIGENCIA:</strong>
        </li>
      </ol>
      <p>
        La presente Pol&iacute;tica de Datos Personales fue creada el d&iacute;a
        05 de Agosto de 2017 y entra en vigencia a partir del d&iacute;a 02 de
        Agosto de 2017.
      </p>
      <p>
        Cualquier cambio que se presente respecto de la presente
        pol&iacute;tica, se informar&aacute; a trav&eacute;s de la
        direcci&oacute;n electr&oacute;nica:
      </p>
      <p>www.notaria13medellin.com</p>
      <p>Atentamente, LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N</p>
      <p>DOMICILIO: Circular 5&ordm; # 70-93</p>
      <p>
        <strong>AVISO DE PRIVACIDAD:</strong>
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <strong>RESPONSABLE DEL TRATAMIENTO:</strong>
              </p>
            </td>
            <td>
              <p>LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>DOMICILIO:</strong>
              </p>
            </td>
            <td>
              <p>Circular 5&ordm; 70-93</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>CORREO ELECTR&Oacute;NICO:</strong>
              </p>
            </td>
            <td>
              <p>
                <a href="mailto:Notaria13@une.net.co">Notaria13@une.net.co</a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <strong>L&Iacute;NEA TELEF&Oacute;NICA:</strong>
              </p>
            </td>
            <td>
              <p>(4) 411-29-28</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>
          FINALIDAD CON LA QUE SE EFECT&Uacute;A LA RECOLECCI&Oacute;N DE DATOS
          PERSONALES Y TRATAMIENTO DE LOS MISMOS:
        </strong>
      </p>
      <p>
        LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N podr&aacute; hacer uso de
        los datos personales para:
      </p>
      <ol>
        <li>
          <strong>a) </strong>Ejecutar la relaci&oacute;n contractual existente
          con sus clientes, proveedores y trabajadores, incluida el pago de
          obligaciones contractuales.
        </li>
        <li>
          <strong>b) </strong>Proveer los servicios y/o los productos requeridos
          por sus usuarios.
        </li>
        <li>
          <strong>c) </strong>Informar sobre nuevos productos o servicios y/o
          sobre cambios en los mismos.
        </li>
        <li>
          <strong>d) </strong>Evaluar la calidad del servicio.
        </li>
        <li>
          <strong>e) </strong>Realizar estudios internos sobre h&aacute;bitos de
          consumo.
        </li>
        <li>
          <strong>f) </strong>Enviar al correo f&iacute;sico,
          electr&oacute;nico, celular o dispositivo m&oacute;vil, v&iacute;a
          mensajes de texto (SMS y/o MMS) o a trav&eacute;s de cualquier otro
          medio an&aacute;logo y/o digital de comunicaci&oacute;n creado o por
          crearse, informaci&oacute;n comercial, publicitaria o promocional
          sobre los productos y/o servicios, eventos y/o promociones de tipo
          comercial o no de estas, con el fin de impulsar, invitar, dirigir,
          ejecutar, informar y de manera general, llevar a cabo campa&ntilde;as,
          promociones o concursos de car&aacute;cter comercial o publicitario,
          adelantados por LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N y/o por
          terceras personas.
        </li>
        <li>
          <strong>g) </strong>Desarrollar el proceso de selecci&oacute;n,
          evaluaci&oacute;n, y vinculaci&oacute;n laboral.
        </li>
        <li>
          <strong>h) </strong>Soportar procesos de auditor&iacute;a interna o
          externa.
        </li>
        <li>
          <strong>i) </strong>Registrar la informaci&oacute;n de empleados y/o
          jubilados (activos e inactivos) en las bases de datos de LA NOTARIA 13
          DEL CIRCULO DE MEDELL&Iacute;N.
        </li>
        <li>
          <strong>j) </strong>Los indicados en la autorizaci&oacute;n otorgada
          por el titular del dato o descritos en el aviso de privacidad
          respectivo, seg&uacute;n sea el caso.
        </li>
        <li>
          <strong>k) </strong>Suministrar, compartir, enviar o entregar sus
          datos personales a empresas filiales, vinculadas, o subordinadas de LA
          NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N. Ubicadas en Colombia o
          cualquier otro pa&iacute;s en el evento que dichas
          compa&ntilde;&iacute;as requieran la informaci&oacute;n para los fines
          aqu&iacute; indicados.
        </li>
      </ol>
      <p>
        Respecto de los datos (i) recolectados directamente en los puntos de
        seguridad, (ii) tomados de los documentos que suministran las personas
        al personal de seguridad y (iii) obtenidos de las videograbaciones que
        se realizan dentro o fuera de las instalaciones de LA NOTARIA 13 DEL
        CIRCULO DE MEDELL&Iacute;N, &eacute;stos se utilizar&aacute;n para fines
        de seguridad de las personas, los bienes e instalaciones de LA NOTARIA
        13 DEL CIRCULO DE MEDELL&Iacute;N y podr&aacute;n ser utilizados como
        prueba en cualquier tipo de proceso.
      </p>
      <p>
        Si un dato personal es proporcionado, dicha informaci&oacute;n
        ser&aacute; utilizada s&oacute;lo para los prop&oacute;sitos aqu&iacute;
        se&ntilde;alados, y por tanto, LA NOTARIA 13 DEL CIRCULO DE
        MEDELL&Iacute;N no proceder&aacute; a vender, licenciar, transmitir, o
        divulgar la misma, salvo que: (i) exista autorizaci&oacute;n expresa
        para hacerlo; (ii) sea necesario para permitir a los contratistas o
        agentes prestar los servicios encomendados; (iii) sea necesario con el
        fin de proveer nuestros servicios y/o productos; (iv) sea necesario
        divulgarla a las entidades que prestan servicios de mercadeo en nombre
        de LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N o a otras entidades con
        las cuales se tengan acuerdos de mercado conjunto; (v) la
        informaci&oacute;n tenga relaci&oacute;n con una fusi&oacute;n,
        consolidaci&oacute;n, adquisici&oacute;n, desinversi&oacute;n, u otro
        proceso de restructuraci&oacute;n de la sociedad; (vi) que sea requerido
        o permitido por la ley.
      </p>
      <p>
        LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N podr&aacute; subcontratar a
        terceros para el procesamiento de determinadas funciones o
        informaci&oacute;n. Cuando efectivamente se subcontrate con terceros el
        procesamiento de informaci&oacute;n personal o se proporcione
        informaci&oacute;n personal a terceros prestadores de servicios, LA
        NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N advierte a dichos terceros
        sobre la necesidad de proteger dicha informaci&oacute;n personal con
        medidas de seguridad apropiadas, se proh&iacute;be el uso de la
        informaci&oacute;n para fines propios y se solicita que no se divulgue
        la informaci&oacute;n personal a otros.
      </p>
      <p>
        <strong>DERECHOS DE LOS TITULARES DE DATOS PERSONALES:</strong>
      </p>
      <p>
        Los titulares de datos personales por s&iacute; o por intermedio de su
        representante y/o apoderado o su causahabiente podr&aacute;n ejercer los
        siguientes derechos, respecto de los datos personales que sean objeto de
        tratamiento por parte de LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N:
      </p>
      <ol>
        <li>
          <strong>Derecho de acceso: </strong>En virtud del cual podr&aacute;
          acceder a los datos personales que est&eacute;n bajo el control de LA
          NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N, para efectos de
          consultarlos de manera gratuita al menos una vez cada mes calendario,
          y cada vez que existan modificaciones sustanciales de las
          Pol&iacute;ticas de Tratamiento de la informaci&oacute;n que motiven
          nuevas consultas.
        </li>
        <li>
          <strong>
            Derecho de actualizaci&oacute;n, rectificaci&oacute;n y
            supresi&oacute;n:{" "}
          </strong>
          En virtud del cual podr&aacute; solicitar la actualizaci&oacute;n,
          rectificaci&oacute;n y/o supresi&oacute;n de los datos personales
          objeto de tratamiento, de tal manera que se satisfagan los
          prop&oacute;sitos del tratamiento.
        </li>
        <li>
          <strong>
            Derecho a solicitar prueba de la autorizaci&oacute;n:{" "}
          </strong>
          Salvo en los eventos en los cuales, seg&uacute;n las normas legales
          vigentes, no se requiera de la autorizaci&oacute;n para realizar el
          tratamiento.
        </li>
        <li>
          <strong>
            Derecho a ser informado respecto del uso del dato personal.
          </strong>
        </li>
        <li>
          <strong>
            Derecho a presentar quejas ante la Superintendencia de Industria y
            Comercio
          </strong>
          : Por infracciones a lo dispuesto en la normatividad vigente sobre
          tratamiento de datos personales.
        </li>
        <li>
          <strong>
            Derecho a requerir el cumplimiento de las &oacute;rdenes emitidas
            por la Superintendencia de Industria y Comercio.
          </strong>
        </li>
      </ol>
      <p>
        <strong>POL&Iacute;TICA DE TRATAMIENTO DE DATOS PERSONALES:</strong>
      </p>
      <p>
        Podr&aacute; ser consultada en la siguiente direcci&oacute;n:
        www.notaria13medellin.com, Cualquier cambio que se presente respecto de
        la presente pol&iacute;tica, se informar&aacute; a trav&eacute;s de la
        direcci&oacute;n electr&oacute;nica:{" "}
        <strong>www.notaria13medellin.com.</strong>
      </p>
      <p>
        En el evento que se recolecten datos personales sensibles, el Titular
        podr&aacute; negarse a autorizar su Tratamiento.
      </p>
      <p>
        LA ALTA DIRECCI&Oacute;N DE LA NOTARIA 13 DEL CIRCULO DE MEDELL&Iacute;N
        ser&aacute; la responsable de atender las peticiones, quejas y reclamos
        que formule el titular del dato en ejercicio de los derechos
        contemplados en el numeral 5 de la presente pol&iacute;tica, a
        excepci&oacute;n del descrito en su literal e). Para tales efectos, el
        titular del dato personal o quien ejerza su representaci&oacute;n
        podr&aacute; enviar su petici&oacute;n, queja o reclamo de lunes a
        viernes de 8:00 A.M a 5:00 P.M al correo electr&oacute;nico{" "}
        <a href="mailto:notaria13@une.net.co">
          <strong>notaria13@une.net.co</strong>
        </a>
        , llamar a la l&iacute;nea telef&oacute;nica de LA NOTARIA 13 DEL
        CIRCULO DE MEDELL&Iacute;N, Medell&iacute;n tel&eacute;fono (4) 4112928,
        o radicarla en las siguiente direcci&oacute;n que correspondientes a
        nuestra oficinas Circular 5&ordm; N # 70-93.
      </p>
      <p>Atentamente,</p>
      <p>
        <strong>LA NOTARIA 13 DEL C&Iacute;RCULO DE MEDELL&Iacute;N.</strong>
      </p>
    </Fragment>
  );
};

export default Habeas;
