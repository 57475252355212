import React from "react";
import Process from "./components/process/Process";
import History from "./components/process/History";
import Admin from "./components/process/Admin/Admin";
import Profile from "./components/profile/Profile";

const protectedRoutes = [
  {
    name: "create",
    exact: true,
    path: "/",
    main: (props: any) => <Process {...props} />,
    public: false,
  },
  {
    name: "history",
    exact: true,
    path: "/history",
    main: (props: any) => <History {...props} />,
    public: false,
  },
  {
    name: "admin",
    exact: true,
    path: "/admin",
    main: (props: any) => <Admin {...props} />,
    public: false,
  },
  {
    name: "profile",
    exact: true,
    path: "/profile",
    main: (props: any) => <Profile {...props} />,
    public: false,
  },
];

export default protectedRoutes;
