import React, { Fragment } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
const Footer = () => {
  return (
    <Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        <span>Circular 5 # 70 - 93 - PBX 411 29 28 </span>
        <span className="linkContainer" style={{ width: "100%" }}>
          {" "}
          <a
            className="link"
            href="http://api.whatsapp.com/send?phone=573216572167&text=Bienvenido a la Notaria 13 de Medellin, ¿En que podemos ayudarte?"
            target="_blank"
            rel="noopener noreferrer"
            title="Share on whatsapp"
          >
            <WhatsAppIcon fontSize={"small"} /> +57 321 6572167
          </a>
        </span>
        <br />
        <span> Medellín Colombia</span>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://notaria13medellin.co/">
          Notaria 13 de Medellín v 1.2.1 Beta
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Fragment>
  );
};

export default Footer;
