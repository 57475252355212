import React from "react";
import SignIn from './components/signin/SignIn';
import Registry from './components/registry/Registry';
import RecoverPassword from './components/recoverpassword/RecoverPassword';

const routes = [
  { name: "Ingresar", path: "/ingresar", exact: true, main: () => <SignIn /> },
  { name: "Registrarse", path: "/registrarse", exact: true, main: () => <Registry /> },
  { name: "CambioClave", path: "/cambioclave", exact: true, main: () => <RecoverPassword /> }
];

export default routes;
