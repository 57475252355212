import React from "react";
import {
  RouteComponentProps,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import { useSelector } from "react-redux";

interface Props extends RouteComponentProps {
  component: any;
  isLoggedIn: boolean;
  public: boolean;
  path: string;
  exact: any;
}

const ProtectedRouteHoc = ({
  component: Component,
  isLoggedIn,
  ...rest
}: Props) => {
  const user = useSelector((state: any) => {
    return state.servicesSelected.user;
  });

  if (isLoggedIn || rest.public) {
    //if is admin user always redirect to admin url
    if (user && user.rol && user.rol === "admin" && rest.path !== "/admin") {
      return <Redirect to={{ pathname: "/admin" }} />;
    } else {
      return (
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props}></Component>;
          }}
        />
      );
    }
  }
  return <Redirect to={{ pathname: "/ingresar" }} />;
};

export default withRouter(ProtectedRouteHoc);
