import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import firebaseConfig from "./firebase.config";
import * as firebase from "firebase";
import protectedRoutes from "./protectedRoutes";
import ProtectedRouteHoc from "./ProtectedRouteHoc";
import NotariaService from "./services/index";
import { SET_USER, SW_INIT, SW_UPDATE } from "./store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import Alert from "./components/common/Alert";

firebase.initializeApp(firebaseConfig);

export const AuthContext = React.createContext({} as Props);

interface Props {
  isLoggedIn: boolean;
  setLoggedIn: Function;
}

function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);

  const isServiceWorkerInitialized = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    (state: any) => state.servicesSelected.serviceWorkerRegistration
  );

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e: any) => {
        if (e.target.state === "activated") {
          //window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(SET_USER(null));
    }
  }, [isLoggedIn]);

  function readSession() {
    const user: any = window.sessionStorage.getItem(
      `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`
    );

    if (user) {
      //get user information
      NotariaService.getUserInformation()
        .then((response: any) => {
          dispatch(SET_USER(response));

          setLoggedIn(true);

          /* messaging.requestPermission().then(function(){
            console.log('have permission')
            return messaging.getToken();
          }).then((token)=>{
            console.log(token)
          })
          
          .catch(function(error){
            console.log(error)
            console.log('error occurred')
          })*/
        })
        .finally(() => {
          setSessionChecked(true);
        });
    } else {
      setSessionChecked(true);
    }
  }
  useEffect(() => {
    readSession();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn } as Props}>
      <div className="App-alert">
        {isServiceWorkerInitialized ? (
          <Alert
            text="Service Worker is initialized for the first time"
            type={SW_INIT}
          />
        ) : null}
        {isServiceWorkerUpdated && updateServiceWorker()}
      </div>
      {sessionChecked ? (
        <Router>
          <Switch>
            {protectedRoutes.map((route) => (
              <ProtectedRouteHoc
                key={route.path}
                isLoggedIn={isLoggedIn}
                path={route.path}
                component={route.main}
                exact={route.exact}
                public={route.public}
              />
            ))}
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            ))}
          </Switch>
        </Router>
      ) : null}
    </AuthContext.Provider>
  );
}

export default App;
