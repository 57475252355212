export type Dict = { [key: string]: any };

const services: Dict = {
  "SOLICITUD DE COPIA(S) DE ESRITURAS": {
    steps: 3,
    active: true,
    sections: [
      {
        stepName: "Paso 1",
        text:
          "<div>Escoja en la siguiente pestaña el tipo de copia que requiere: <p>Nota: Señor usuario se le recomienda consultar en la entidad donde va a hacer uso de la copia de escritura, si la requiere simple o auténtica</p></div>",
        validateForm: true,
        form: {
          copyTypes: {
            type: "Select",
            label: "Selecciona tipo de copia",
            summaryLabel: "Tipo de copia",
            options: [
              {
                id: 1,
                value: "Copia simple de escritura.",
              },
              {
                id: 2,
                value: "Copia autentica de escritura.",
              },
              {
                id: 3,
                value: "Certificados y vigencias.",
              },
            ],
            default: "option1",
            required: true,
            value: "",
          },
          shipping: {
            type: "Checkbox",
            label: "¿Requiere envio físico? Esto tendra un costo adicional",
            required: false,
            value: false,
          },
          envioLink: {
            type: "HTML",
            required: false,
            value:
              "<div class='linkContainer'>Puede conocer el valor del envio desde el cotizador de <a class='link' target='_blank' href='https://www.servientrega.com/wps/portal/Colombia/transacciones-personas/cotizador'>Servientrega.com</a></div>",
          },
        },
      },
      {
        stepName: "Paso 2",
        text: "",
        validateForm: true,
        form: {
          note: {
            type: "HTML",
            required: false,
            value:
              "<div>Nota: señor usuario, si no tiene conocimiento del número o año de la escritura, remítase al certificado de libertad y tradición del inmueble, para consultar esta información.</div>  ",
          },
          deepNumber: {
            type: "TextField",
            label: "Número de escritura",
            value: "",
            required: true,
          },
          dateyear: {
            type: "DatePicker",
            label: "Año Escritura (AAAA)",
            required: false,
            value: null,
            errormenssage: "Año Invalido",
            format: "yyyy",
            mask: "____",
            disabled: false,
            visible: true,
          },
          hasdate: {
            type: "Checkbox",
            label: "¿Conoce la fecha completa de otorgamiento de la escritura?",
            required: false,
            value: false,
            format: "MM/dd/yyyy",
            depends: {
              element: "date",
              value: true,
            },
          },

          date: {
            type: "DatePicker",
            label: "Fecha de Escritura (Mes/Día/Año)",
            required: false,
            value: null,
            disabled: true,
            visible: false,
          },
          noteComentario: {
            type: "HTML",
            required: false,
            value:
              "<div style='width:45ch;font-weight:bolder'>En el siguiente campo agregue Información o comentario adicional sobre su trámite</div>  ",
          },
          comment: {
            type: "TextField",
            label: "Comentario",
            required: false,
            multiline: true,
            value: "",
            rows: 4,
          },
        },
      },
      {
        stepName: "Paso 3",
        text:
          "Al presionar el boton terminar se generará la orden del siguiente tramite",
        validateForm: false,
        showSummary: true,
        form: {},
      },
    ],
  },
  "SOLICITUD DE COPIA (S) AUTENTICA DE REGISTRO CIVIL": {
    active: true,
    validateForm: true,
    steps: 3,
    sections: [
      {
        stepName: "Paso 1",
        text:
          "<div>Escoja en la siguiente pestaña el tipo de registro civil que requiere: </div>",
        validateForm: true,
        form: {
          copyTypes: {
            type: "Select",
            label: "Selecciona tipo de copia de Registro Civil",
            summaryLabel: "Tipo de copia",
            options: [
              {
                id: 1,
                value: "Copia autentica registro civil de nacimiento. ",
              },
              {
                id: 2,
                value: "Copia autentica registro civil de matrimonio.",
              },
              {
                id: 3,
                value: "Copia autentica registro civil de defunción.",
              },
            ],
            default: "option1",
            required: true,
            value: "",
          },
          shipping: {
            type: "Radiogroup",
            label: "Seleccciona el tipo de envio",
            options: [
              {
                id: 1,
                value:
                  "Digital (El valor total incluye firma digital+transferencia electrónica) ",
              },
              {
                id: 2,
                value:
                  "Físico (El valor varia según ubicación del destinatario). ",
              },
            ],
            required: true,
            value: false,
          },
          /*signature: {
            type: "Checkbox",
            label:
              "Requiere Firmar Digital (En caso de que el ó la entidad que lo requiera lo exija, este tendra un costo adicional de $8.449 iva incluido)",
            required: false,
            value: false,
          },*/
          envioLink: {
            type: "HTML",
            required: false,
            value:
              "<div class='linkContainer'>Puede conocer el valor del envio desde el cotizador de <a class='link' target='_blank' href='https://www.servientrega.com/wps/portal/Colombia/transacciones-personas/cotizador'>Servientrega.com</a></div>",
          },
        },
      },
      {
        stepName: "Paso 2",
        text:
          "<div>Ingrese los siguientes datos para continuar con la solicitud: </div>",
        validateForm: true,
        switchFormByPreviousStep: "copyTypes",
        optionsForms: {
          1: {
            serialNumber: {
              type: "TextField",
              label: "Número del indicativo serial",
              value: "",
              required: true,
            },
            name: {
              type: "TextField",
              label: "Nombre del inscrito ",
              value: "",
              required: true,
            },
            date: {
              type: "DatePicker",
              label: "Fecha de Nacimiento (Mes/Día/Año)",
              required: true,
              value: null,
              disabled: false,
            },
            noteComentario: {
              type: "HTML",
              required: false,
              value:
                "<div style='width:45ch;font-weight:bolder'>En el siguiente campo agregue Información o comentario adicional sobre su trámite</div>  ",
            },
            comment: {
              type: "TextField",
              label: "Comentario",
              required: false,
              multiline: true,
              value: "",
              rows: 4,
            },
            note: {
              type: "HTML",
              required: false,
              value:
                "<div>Nota: señor usuario, si no tiene conocimiento del número del indicativo serial que " +
                "identifica su registro, puede consultarlo en el siguiente link de la plataforma virtual de la registraduria nacional del estado civil <a href='https://consultasrc.registraduria.gov.co:28080/ProyectoSCCRC/' target='_blank'>consultar</a>.</div>  ",
            },
          },
          2: {
            serialNumber: {
              type: "TextField",
              label: "Número del indicativo serial",
              value: "",
              required: true,
            },
            name: {
              type: "TextField",
              label: "Nombre completo de uno de los conyugues",
              value: "",
              required: true,
            },
            date: {
              type: "DatePicker",
              label: "Fecha del matrimonio (Mes/Día/Año)",
              required: false,
              value: null,
              disabled: false,
            },
            noteComentario: {
              type: "HTML",
              required: false,
              value:
                "<div style='width:45ch;font-weight:bolder'>En el siguiente campo agregue Información o comentario adicional sobre su trámite</div>  ",
            },
            comment: {
              type: "TextField",
              label: "Comentario",
              required: false,
              multiline: true,
              value: "",
              rows: 4,
            },
            note: {
              type: "HTML",
              required: false,
              value:
                "<div>Nota: señor usuario, si no tiene conocimiento del número del indicativo serial que " +
                "identifica su registro, puede consultarlo en el siguiente link de la plataforma virtual de la registraduria nacional del estado civil <a href='https://consultasrc.registraduria.gov.co:28080/ProyectoSCCRC/' target='_blank'>consultar</a>.</div>  ",
            },
          },
          3: {
            serialNumber: {
              type: "TextField",
              label: "Número del indicativo serial",
              value: "",
              required: true,
            },
            name: {
              type: "TextField",
              label: "Nombre completo del inscrito",
              value: "",
              required: true,
            },
            date: {
              type: "DatePicker",
              label: "Fecha de defunción (Mes/Día/Año)",
              required: false,
              value: null,
              disabled: false,
            },
            noteComentario: {
              type: "HTML",
              required: false,
              value:
                "<div style='width:45ch;font-weight:bolder'>En el siguiente campo agregue Información o comentario adicional sobre su trámite</div>  ",
            },
            comment: {
              type: "TextField",
              label: "Comentario",
              required: false,

              value: "",
              rows: 4,
            },
          },
        },
      },
      {
        stepName: "Paso 3",
        text:
          "Al presionar el boton terminar se generará la orden del siguiente tramite",
        validateForm: false,
        showSummary: true,
        form: {},
      },
    ],
  },
  "SOLICITUD DE ELABORACIÓN DE ESCRITURAS": {
    steps: 2,
    active: true,
    validateForm: true,
    sections: [
      {
        stepName: "Paso 1",
        text:
          "<div><p>Esta opción de la plataforma le permitirá radicar solicitudes de elaboración de actos escriturarios, y le permite contactar a nuestros funcionarios, conocer y adjuntar los documentos necesarios para el trámite desde su casa.  </p></div>",
        validateForm: true,
        Groups: {
          Group1: {
            required: true,
            minValid: 2,
          },
        },
        form: {
          multiSelect: {
            type: "Select",
            label: "Selecciona una o varias escrituras que desee elaborar",
            summaryLabel: "Escrituras",
            multiple: true,
            options: [
              {
                id: 1,
                value: "ACTUALIZACIÓN DE NOMENCLATURA",
              },
              {
                id: 2,
                value: "AFECTACIÓN A VIVIENDA FAMILIAR",
              },
              {
                id: 3,
                value: "CANCELACIÓN A LA AFECTACION DE VIVIENDA FAMILIAR",
              },
              {
                id: 4,
                value:
                  "AUTORIZACIÓN PERMANENTE PARA SALIDA DEL PAÍS DE UN MENOR DE EDAD",
              },
              {
                id: 5,
                value: "CAMBIO DE NOMBRE",
              },
              {
                id: 6,
                value: "CONSTITUCION DE PATRIMONIO DE FAMILIA",
              },
              {
                id: 7,
                value: "CESION DE CUOTAS",
              },
              {
                id: 8,
                value: "COMPRAVENTA",
              },
              {
                id: 9,
                value: "CONSTITUCION DE SOCIEDAD",
              },
              {
                id: 10,
                value: "CORRECCION EN EL REGISTRO CIVIL",
              },
              {
                id: 11,
                value: "DISOLUCION DE SOCIEDAD COMERCIAL",
              },
              {
                id: 12,
                value: "DISOLUCION Y LIQUIDACION DE LA SOCIEDAD CONYUGAL",
              },
              {
                id: 13,
                value: "HIPOTECA",
              },
              {
                id: 14,
                value: "CANCELACION DE HIPOTECA",
              },
              {
                id: 15,
                value: "INVENTARIO SOLEMNE DE BIENES",
              },
              {
                id: 16,
                value: "LIQUIDACION DE SOCIEDAD COMERCIAL",
              },
              {
                id: 17,
                value: "PODER GENERAL",
              },
              {
                id: 18,
                value: "RECONOCIMIENTO DE HIJOS",
              },
              {
                id: 19,
                value: "REGLAMENTO DE PROPIEDAD HORIZONTAL",
              },
              {
                id: 20,
                value: "REFORMA AL REGLAMENTO DE PROPIEDAD HORIZONTAL",
              },
              {
                id: 21,
                value: "REFORMA DE ESTATUTOS",
              },
              {
                id: 22,
                value: "TRANSFORMACION DE SOCIEDAD COMERCIAL",
              },
              {
                id: 23,
                value: "TESTAMENTO",
              },
              {
                id: 24,
                value: "ACLARACIÓN DE ESCRITURA PÚBLICA",
              },
              {
                id: 25,
                value: "AUTORIZACIÓN PARA ENAJENAR BIEN DE MENOR DE EDAD",
              },
              {
                id: 26,
                value: "CANCELACIÓN DE ESCRITURA POR ORDEN JUDICIAL",
              },
              {
                id: 27,
                value: "CANCELACIÓN DE HIPOTECA POR ORDEN JUDICIAL",
              },
              {
                id: 28,
                value: "CANCELACIÓN DE USUFRUCTO",
              },
              {
                id: 29,
                value: "CAPITULACIONES MATRIMONIALES O MARITALES",
              },
              {
                id: 30,
                value: "COMPRAVENTA DE DERECHOS HERENCIALES",
              },
              {
                id: 31,
                value: "DACIÓN EN PAGO",
              },
              {
                id: 32,
                value: "DECLARACIÓN DE CONSTRUCCIÓN",
              },
              {
                id: 33,
                value: "DONACIÓN",
              },
              {
                id: 34,
                value: "FIDEICOMISO CIVIL",
              },
              {
                id: 35,
                value: "LOTEO",
              },
            ],
            default: "option1",
            required: true,
            value: [],
            depends: {
              element: [
                "option1Value",
                "option2Value",
                "option3Value",
                "option4Value",
                "option5Value",
                "option6Value",
                "option7Value",
                "option8Value",
                "option9Value",
                "option10Value",
                "option11Value",
                "option12Value",
                "option13Value",
                "option14Value",
                "option15Value",
                "html1Value",
                "html2Value",
                "html3Value",
                "html4Value",
                "html5Value",
                "html7Value",
                "html8Value",
                "html9Value",
                "html10Value",
                "html11Value",
                "html12Value",
                "html13Value",
                "html14Value",
                "html15Value",
                "html16Value",
                "html17Value",
                "html18Value",
                "html19Value",
                "html20Value",
                "html21Value",
                "html22Value",
                "html23Value",
                "html24Value",
                "html25Value",
                "html26Value",
                "html27Value",
                "html28Value",
                "html29Value",
                "html30Value",
                "html31Value",
                "html32Value",
                "html33Value",
                "html34Value",
                "html35Value",
                "html36Value",
              ],
              value: true,
            },
          },
          nota: {
            type: "HTML",
            required: false,
            value:
              "<div style='font-weight:bolder'>Para la radicación y solicitud de su trámite de escrituración, y tener conocimiento de los requisitos que debe aportar, favor revisar los links que apareceran a medida que selecciones los tramites a solicitar</div>",
          },
          html1Value: {
            type: "HTML",
            label: "ACTUALIZACIÓN DE NOMENCLATURA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a class='link' href='https://www.notaria13medellin.co/escrituracion/actualizacion-de-nomenclatura' target='_blank'>ACTUALIZACIÓN DE NOMENCLATURA</a><div>",
            disabled: true,
            visible: false,
          },
          html2Value: {
            type: "HTML",
            label: "AFECTACIÓN A VIVIENDA FAMILIAR",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/afectacion-a-vivienda-familiar' target='_blank'>AFECTACIÓN A VIVIENDA FAMILIAR</a></div>",
            disabled: true,
            visible: false,
          },
          html3Value: {
            type: "HTML",
            label: "CANCELACIÓN A LA AFECTACION DE VIVIENDA FAMILIAR",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cancelacion-a-la-afectacion-de-vivienda-familiar' target='_blank'>CANCELACIÓN A LA AFECTACION DE VIVIENDA FAMILIAR</a></div>",
            disabled: true,
            visible: false,
          },
          html4Value: {
            type: "HTML",
            label:
              "AUTORIZACIÓN PERMANENTE PARA SALIDA DEL PAÍS DE UN MENOR DE EDAD",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/autorizacion-permanente-para-salida-del-pais-de-un-menor-de-edad' target='_blank'>AUTORIZACIÓN PERMANENTE PARA SALIDA DEL PAÍS DE UN MENOR DE EDAD </a></div>",
            disabled: true,
            visible: false,
          },
          html5Value: {
            type: "HTML",
            label: "CAMBIO DE NOMBRE",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cambio-de-nombre' target='_blank'>CAMBIO DE NOMBRE</a></div>",
            disabled: true,
            visible: false,
          },
          html11Value: {
            type: "HTML",
            label: "CORRECCION EN EL REGISTRO CIVIL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/correccion-en-el-registro-civil' target='_blank'>CORRECCION EN EL REGISTRO CIVIL</a></div>",
            disabled: true,
            visible: false,
          },
          html12Value: {
            type: "HTML",
            label: "DISOLUCION DE SOCIEDAD COMERCIAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/disolucion-de-sociedad-comercial' target='_blank'>DISOLUCION DE SOCIEDAD COMERCIAL</a></div>",
            disabled: true,
            visible: false,
          },
          html18Value: {
            type: "HTML",
            label: "PODER GENERAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/poder-general' target='_blank'>PODER GENERAL</a></div>",
            disabled: true,
            visible: false,
          },
          html19Value: {
            type: "HTML",
            label: "RECONOCIMIENTO DE HIJOS",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/reconocimiento-de-hijos' target='_blank'>RECONOCIMIENTO DE HIJOS</a></div>",
            disabled: true,
            visible: false,
          },
          html20Value: {
            type: "HTML",
            label: "REGLAMENTO DE PROPIEDAD HORIZONTAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/reglamento-de-propiedad-horizontal' target='_blank'>REGLAMENTO DE PROPIEDAD HORIZONTAL</a></div>",
            disabled: true,
            visible: false,
          },
          html21Value: {
            type: "HTML",
            label: "REFORMA AL REGLAMENTO DE PROPIEDAD HORIZONTAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/reforma-al-reglamento-de-propiedad-horizontal' target='_blank'>REFORMA AL REGLAMENTO DE PROPIEDAD HORIZONTAL</a></div>",
            disabled: true,
            visible: false,
          },
          html22Value: {
            type: "HTML",
            label: "REFORMA DE ESTATUTOS",
            preLabel: "Valor del acto",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/reforma-de-estatutos' target='_blank'>REFORMA DE ESTATUTOS</a></div>",
            disabled: true,
            visible: false,
          },
          html23Value: {
            type: "HTML",
            label: "TRANSFORMACION DE SOCIEDAD COMERCIAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/transformacion-de-sociedad-comercial' target='_blank'>TRANSFORMACION DE SOCIEDAD COMERCIAL</a></div>",
            disabled: true,
            visible: false,
          },
          html24Value: {
            type: "HTML",
            label: "TESTAMENTO",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/aclaracion-de-escritura-publica' target='_blank'>TESTAMENTO</a></div>",
            disabled: true,
            visible: false,
          },
          html25Value: {
            type: "HTML",
            label: "ACLARACIÓN DE ESCRITURA PÚBLICA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/testamento' target='_blank'>ACLARACIÓN DE ESCRITURA PÚBLICA</a></div>",
            disabled: true,
            visible: false,
          },
          html26Value: {
            type: "HTML",
            label: "AUTORIZACIÓN PARA ENAJENAR BIEN DE MENOR DE EDAD",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/autorizacion-para-enajenar-bien-de-menor-de-edad' target='_blank'>AUTORIZACIÓN PARA ENAJENAR BIEN DE MENOR DE EDAD</a></div>",
            disabled: true,
            visible: false,
          },
          html27Value: {
            type: "HTML",
            label: "CANCELACIÓN DE ESCRITURA POR ORDEN JUDICIAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cancelacion-de-escritura-publica-por-orden-judicial' target='_blank'>CANCELACIÓN DE ESCRITURA POR ORDEN JUDICIAL</a></div>",
            disabled: true,
            visible: false,
          },
          html29Value: {
            type: "HTML",
            label: "CANCELACIÓN DE USUFRUCTO",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cancelacion-de-usufructo' target='_blank'>CANCELACIÓN DE USUFRUCTO</a></div>",
            disabled: true,
            visible: false,
          },
          html7Value: {
            type: "HTML",
            label: "CONSTITUCION DE PATRIMONIO DE FAMILIA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/constitucion-de-patrimonio-de-familia' target='_blank'>CONSTITUCION DE PATRIMONIO DE FAMILIA</a></div>",
            disabled: true,
            visible: false,
          },
          option1Value: {
            type: "Input",
            label: "CONSTITUCION DE PATRIMONIO DE FAMILIA",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html8Value: {
            type: "HTML",
            label: "CESION DE CUOTAS",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/sesion-de-cuotas' target='_blank'>CESION DE CUOTAS</a></div>",
            disabled: true,
            visible: false,
          },
          option2Value: {
            type: "Input",
            label: "CESION DE CUOTAS",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html9Value: {
            type: "HTML",
            label: "COMPRAVENTA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/compraventa' target='_blank'>COMPRAVENTA</a></div>",
            disabled: true,
            visible: false,
          },
          option3Value: {
            type: "Input",
            label: "COMPRAVENTA",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html10Value: {
            type: "HTML",
            label: "CONSTITUCION DE SOCIEDAD",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/constitucion-de-sociedad' target='_blank'>CONSTITUCION DE SOCIEDAD</a></div>",
            disabled: true,
            visible: false,
          },
          option4Value: {
            type: "Input",
            label: "CONSTITUCION DE SOCIEDAD",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html13Value: {
            type: "HTML",
            label: "DISOLUCION Y LIQUIDACION DE LA SOCIEDAD CONYUGAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/disolucion-y-liquidacion-de-la-sociedad-conyugal' target='_blank'>DISOLUCION Y LIQUIDACION DE LA SOCIEDAD CONYUGAL </a></div>",
            disabled: true,
            visible: false,
          },
          option5Value: {
            type: "Input",
            label: "DISOLUCION Y LIQUIDACION DE LA SOCIEDAD CONYUGAL",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html14Value: {
            type: "HTML",
            label: "HIPOTECA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/hipoteca' target='_blank'>HIPOTECA</a></div>",
            disabled: true,
            visible: false,
          },
          option6Value: {
            type: "Input",
            label: "HIPOTECA",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html15Value: {
            type: "HTML",
            label: "CANCELACION DE HIPOTECA",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cancelacion-de-hipoteca' target='_blank'>CANCELACION DE HIPOTECA</a></div>",
            disabled: true,
            visible: false,
          },
          option9Value: {
            type: "Input",
            label: "CANCELACION DE HIPOTECA",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html16Value: {
            type: "HTML",
            label: "INVENTARIO SOLEMNE DE BIENES",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/inventario-solemne-de-bienes' target='_blank'>INVENTARIO SOLEMNE DE BIENES</a></div>",
            disabled: true,
            visible: false,
          },
          option7Value: {
            type: "Input",
            label: "Valor del Acto INVENTARIO SOLEMNE DE BIENES",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html17Value: {
            type: "HTML",
            label: "LIQUIDACION DE SOCIEDAD COMERCIAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/liquidacion-de-sociedad-comercial' target='_blank'>LIQUIDACION DE SOCIEDAD COMERCIAL</a></div>",
            disabled: true,
            visible: false,
          },
          option8Value: {
            type: "Input",
            label: "LIQUIDACION DE SOCIEDAD COMERCIAL",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html28Value: {
            type: "HTML",
            label: "CANCELACIÓN DE HIPOTECA POR ORDEN JUDICIAL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/cancelacion-de-hipoteca-por-orden-judicial' target='_blank'>CANCELACIÓN DE HIPOTECA POR ORDEN JUDICIAL</a></div>",
            disabled: true,
            visible: false,
          },
          html35Value: {
            type: "HTML",
            label: "FIDEICOMISO CIVIL",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/fideicomiso-civil' target='_blank'>FIDEICOMISO CIVIL</a></div>",
            disabled: true,
            visible: false,
          },
          html36Value: {
            type: "HTML",
            label: "LOTEO",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/loteo' target='_blank'>LOTEO</a></div>",
            disabled: true,
            visible: false,
          },
          option10Value: {
            type: "Input",
            label: "CANCELACIÓN DE HIPOTECA POR ORDEN JUDICIAL",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html30Value: {
            type: "HTML",
            label: "CAPITULACIONES MATRIMONIALES O MARITALES",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/capitulaciones-matrimoniales-o-maritales' target='_blank'>CAPITULACIONES MATRIMONIALES O MARITALES</a></div>",
            disabled: true,
            visible: false,
          },
          option11Value: {
            type: "Input",
            label: "CAPITULACIONES MATRIMONIALES O MARITALES",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html31Value: {
            type: "HTML",
            label: "COMPRAVENTA DE DERECHOS HERENCIALES",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/compraventa-de-derechos-herenciales' target='_blank'>COMPRAVENTA DE DERECHOS HERENCIALES</a></div>",
            disabled: true,
            visible: false,
          },
          option12Value: {
            type: "Input",
            label: "COMPRAVENTA DE DERECHOS HERENCIALES",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html32Value: {
            type: "HTML",
            label: "DACIÓN EN PAGO",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/dacion-en-pago' target='_blank'>DACIÓN EN PAGO</a></div>",
            disabled: true,
            visible: false,
          },
          option13Value: {
            type: "Input",
            label: "DACIÓN EN PAGO",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html33Value: {
            type: "HTML",
            label: "DECLARACIÓN DE CONSTRUCCIÓN",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/declaracion-de-construccion' target='_blank'>DECLARACIÓN DE CONSTRUCCIÓN</a></div>",
            disabled: true,
            visible: false,
          },
          option14Value: {
            type: "Input",
            label: "DECLARACIÓN DE CONSTRUCCIÓN",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          html34Value: {
            type: "HTML",
            label: "DONACIÓN",
            required: true,
            value:
              "<div class='linkContainer'>Requisitos: <a  class='link' href='https://www.notaria13medellin.co/escrituracion/donacion' target='_blank'>DONACIÓN</a></div>",
            disabled: true,
            visible: false,
          },
          option15Value: {
            type: "Input",
            label: "DONACIÓN",
            preLabel: "Valor del acto",
            required: true,
            value: "",
            disabled: true,
            visible: false,
          },
          observaciones: {
            type: "HTML",
            required: false,
            value:
              "<div class='linkContainer' style='margin-top:40px; text-align: justify'>En el siguiente campo si ya ha hecho trámites con nosotros, puede incluir el nombre de uno de nuestros protocolistas para vincular el proceso con el</div>",
          },
          comment: {
            type: "TextField",
            label: "Observaciones",
            required: false,
            multiline: true,
            value: "",
            rows: 4,
          },
        },
      },
      {
        stepName: "Paso 2",
        text:
          "Al presionar el boton terminar se generará la orden del siguiente tramite",
        validateForm: false,
        showSummary: true,
        form: {},
      },
    ],
  },
};

export default services;
