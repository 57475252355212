import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";

const serviceData = createSlice({
  name: "serviceData",
  initialState: {
    service: null,
    serviceInfo: {},
    activeStep: 0,
    user: null,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  },
  reducers: {
    SERVICE_DATA: (state: any, action: any) => {
      state.serviceInfo = action.payload;
    },
    SERVICE_SELECT: (state: any, action: any) => {
      state.service = action.payload;
    },
    SET_ACTIVE_STEP: (state: any, action: any) => {
      state.activeStep = action.payload;
    },
    GET_USER: (state: any, action: any) => {
      return;
    },
    SET_USER: (state: any, action: any) => {
      state.user = action.payload;
    },
    SW_INIT: (state: any, action: any) => {
      state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
    },
    SW_UPDATE: (state: any, action: any) => {
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = action.payload;
    },
  },
});

export const serviceAction = serviceData.actions;
export const serviceReducer = serviceData.reducer;

export default combineReducers({
  servicesSelected: serviceData.reducer,
});
