import React, { useEffect, useState, FormEvent, Fragment } from "react";
import services from "../../resources/services";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Layout from "../layout/Layout";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import StepProcess from "./StepProcess/StepProcess";
import { useDispatch, useSelector } from "react-redux";
import {
  SERVICE_DATA,
  SERVICE_SELECT,
  SET_ACTIVE_STEP,
} from "../../store/actions/index";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 350,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      textAlign: "center",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

const Process = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tramites, setTramites] = useState([]);
  const [service, setService] = useState<string>("");
  const [startStep, setStartStep] = useState<boolean>(false);

  let user = useSelector((state: any) => {
    return state.servicesSelected.user;
  });

  const handleChangeService = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(SERVICE_DATA(services[event.target.value as string]));
    dispatch(SERVICE_SELECT(event.target.value as string));
    setService(event.target.value as string);
  };
  const cancelProcess = () => {
    setStartStep(false);
    setService("");
    dispatch(SERVICE_DATA({}));
    dispatch(SERVICE_SELECT(null));
    dispatch(SET_ACTIVE_STEP(0));
  };
  let serviceOptions: any = [];
  useEffect(() => {
    console.log(services);
    Object.keys(services).forEach(function (item: string) {
      serviceOptions.push(item);
    });
    setTramites(serviceOptions);
  }, []);

  const startProcess = (e: FormEvent) => {
    e.preventDefault();
    dispatch(SET_ACTIVE_STEP(0));
    setStartStep(true);
  };

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        {!startStep ? (
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <p>
                Bienvenido{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {user.name} {user.lastName}.
                </span>
                <br />
                <br />
                Para su comodidad y brindarle una mejor atención, le informamos
                que por medio de nuestra plataforma virtual podrá solicitar los
                siguientes tramites notariales desde su casa:
              </p>
              <p>Escoja en la siguiente pestaña el trámite que requiere:</p>
            </Grid>
            <Grid item xs={12}>
              <form
                className={classes.form}
                onSubmit={(e) => startProcess(e)}
                noValidate
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Tramite
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={service}
                    onChange={handleChangeService}
                    label="Tramite"
                  >
                    {tramites.map((value: any) => {
                      return (
                        <MenuItem value={value} key={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.submit}
                  fullWidth={true}
                  disabled={service === null || !service ? true : false}
                >
                  Iniciar
                </Button>
              </form>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Grid
              container
              justify="flex-end"
              direction="column"
              alignItems="flex-end"
              spacing={4}
            >
              <Grid item xs={12} style={{ margin: 20 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelProcess}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>

            <StepProcess></StepProcess>
          </Fragment>
        )}
      </Container>
    </Layout>
  );
};

export default Process;
