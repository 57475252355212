import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "./Alert.scss";

interface Props {
  text: string;
  buttonText?: string;
  type: any;
  onClick?: any;
}

const Alert = ({ text, buttonText, type, onClick }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        console.log("Installed");
        dispatch({ type });
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <div className="alert">
      {text} {buttonText && <button onClick={onClick}>{buttonText}</button>}
    </div>
  );
};

export default Alert;
