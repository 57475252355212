import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
//import rootSaga from './sagas/index';
import notariaSaga from "./sagas/index";
import reducer from "./slices/index";

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const store = configureStore({
  reducer: reducer,
  middleware,
});

sagaMiddleware.run(notariaSaga);

export default store;
