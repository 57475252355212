import React, { useState, Fragment, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import * as firebase from "firebase";

import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../common/Footer";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../App";
import { Alert } from "@material-ui/lab";
import logo from "../../images/logo-shield.svg";
import queryString from "query-string";

import { RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { timeout } from "q";
interface ChildComponentProps extends RouteComponentProps<any> {
  history: History;
  location: any;
  /* other props for ChildComponent */
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

const RecoverPassword = ({ history, location }: ChildComponentProps) => {
  const [password, setPassword] = useState<string>("");
  const [codeValue, setCodeVal] = useState();

  const Auth = useContext(AuthContext);
  const handleForm = (e: any) => {
    e.preventDefault();
    firebase
      .auth()
      .confirmPasswordReset(codeValue.oobCode, password)
      .then(() => {
        let message = "Contraseña actualizada exitosamente";
        history.push(`/ingresar?type=success&message=${message}`);
      })
      .catch((e) => {
        history.push(`/ingresar?type=error&message=${e.message}`);
      });
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    setCodeVal(values);
    firebase
      .auth()
      .verifyPasswordResetCode(values.oobCode as string)
      .then((res: string) => {})
      .catch((e) => {
        history.push(`/ingresar?type=error&message=${e.message}`);
      });
  }, []);

  const classes = useStyles();

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img src={logo} width="120px" />
          </div>
          <Typography component="h1" variant="h5">
            Cambio de contraseña
          </Typography>

          <form
            className={classes.form}
            onSubmit={(e) => handleForm(e)}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Nueva Contraseña"
              name="password"
              type="password"
              autoComplete="password"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Cambiar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Footer />
        </Box>
      </Container>
    </Fragment>
  );
};

export default withRouter(RecoverPassword);
