import React, { forwardRef, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import Container from "@material-ui/core/Container";
import MaterialTable, { Icons } from "material-table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DateFnsAdapter from "@date-io/date-fns";
import notariaService from "../../../services/index";
import { find } from "lodash";
import Adminstyle from "./Admin.module.scss";
import { FormControl, Select, InputLabel, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";

import Grid from "@material-ui/core/Grid";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Admin = () => {
  const [registers, setRegisters] = useState([]);
  const [summary, setSummary] = useState([]);
  const [userSummary, setUserSummary] = useState<any>({});
  const [loading, setLoading] = useState(true);
  //this will show all the process that a user has in a table and the status
  const dateFns = new DateFnsAdapter();
  const [open, setOpen] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);

  const [users, setUsers] = React.useState<any>({});

  const usersList: { [key: string]: string } = {};
  const usersListData: { [key: string]: any } = {};
  //change the status of the current resquest
  const [status, setStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [statusSelected, setStatusSelected] = React.useState<string[]>([
    "creada",
    "EN PROCESO",
  ]);

  let user = useSelector((state: any) => {
    return state.servicesSelected.user;
  });

  const classes = useStyles();

  const optionsStatus = [
    "Creada",
    "En Proceso",
    "Cancelada",
    "Pendiente de Pago",
    "Terminada",
  ];

  const optionsStatus2 = [
    "creada",
    "EN PROCESO",
    "CANCELADA",
    "PENDIENTE DE PAGO",
    "TERMINADA",
  ];

  const changeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleClickOpenUser = (uid: string) => {
    setUserSummary(users[uid]);
    setOpenUser(true);
  };
  const updateStatus = () => {
    notariaService.updateStatus(status, selectedId).then(() => {
      //Update the data of the table
      let row: any = find(registers, function (o: any) {
        return o.id === selectedId;
      });
      row.status = status;
      setRegisters(registers);

      handleClose();
    });
  };

  const handleClickOpen = (data: any, status: string, id: string) => {
    let dataSummary: any[] = [];
    Object.keys(data).forEach(function (key, value) {
      dataSummary.push(data[key]);
    });
    if (!data["status"]) {
      data["status"] = { label: "Estado", value: status };
      dataSummary.push(data["status"]);
    }
    setStatus(status);
    setSelectedId(id);
    setSummary(dataSummary as any);
    setOpen(true);
  };

  const handleUserClose = () => {
    setOpenUser(false);
  };
  //Close the service information modal
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //Filter the content according with the admin user email
    let filterBy;
    switch (user.uid) {
      case "rST8XdkAQ0W41XB3qt0CxqmnYHo2":
        filterBy = "SOLICITUD DE ELABORACIÓN DE ESCRITURAS";
        break;
      case "sWzqT7bxUdZSX6NqlB51jjdr2F52": //protocolonotaria13@gmail.com
        filterBy = "SOLICITUD DE COPIA(S) DE ESRITURAS";
        break;
      case "wxDX9GpIENVQD30pz8jOPMj4YRM2": //registrocivil13@gmail.com
        filterBy = "SOLICITUD DE COPIA (S) AUTENTICA DE REGISTRO CIVIL";

        break;
      default:
        filterBy = "ALL";
        break;
    }

    notariaService
      .getHistory(filterBy, statusSelected)
      .then((querySnapshot: any) => {
        let tableData: any = [];
        let count: number = 0;
        let countUsers: number = 0;
        querySnapshot.forEach((doc: any) => {
          //look for the user if is on cache skip and put the value
          if (!usersList[doc.data().user]) {
            usersList[doc.data().user] = doc.data().user;
            count++;
          }
        });

        Object.keys(usersList).forEach((k, i) => {
          notariaService
            .getUserById(usersList[k])
            .then((response: any) => {
              usersListData[response.uid] = response;
            })
            .finally(() => {
              countUsers++;
              if (countUsers === count) {
                querySnapshot.forEach((doc: any, key: any) => {
                  //look for the user if is on cache skip and put the value
                  tableData.push({
                    id: doc.id,
                    uid: doc.data().user,
                    type: doc.data().type,
                    cellphone: usersListData[doc.data().user].cellphone,
                    user:
                      usersListData[doc.data().user].name +
                      " " +
                      usersListData[doc.data().user].lastName,
                    date: dateFns.format(
                      doc.data().date.toDate(),
                      "MM/dd/yyyy"
                    ),
                    information: doc.data().information,
                    status: doc.data().status ?? "En proceso",
                  });
                });
                setUsers(usersListData);
                setRegisters(tableData);
              }
            });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [statusSelected]);

  var popupSummary = {
    fontWeight: "bold" as "bold",
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusSelected(event.target.value as string[]);
  };

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h1>Historial de Tramites</h1>
          </Grid>
          <Grid item container direction={"row"} justify={"flex-end"} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Estado</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                multiple
                value={statusSelected}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) =>
                  (selected as string[]).join(", ").toUpperCase()
                }
                MenuProps={MenuProps}
              >
                {optionsStatus2.map((status) => (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={statusSelected.indexOf(status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <MaterialTable
          icons={tableIcons}
          title={""}
          isLoading={loading}
          columns={[
            {
              title: "Usuario",
              field: "user",
              render: (rowData: any) => (
                <Button
                  size="small"
                  onClick={() => handleClickOpenUser(rowData.uid)}
                >
                  {rowData.user}
                </Button>
              ),
            },
            { title: "Celular", field: "cellphone" },
            { title: "Tipo de Tramite", field: "type" },
            {
              title: "Fecha",
              field: "date",
              defaultSort: "asc",
              customSort: (a, b) => {
                let dateB = new Date(b.date).getTime();
                let dateA = new Date(a.date).getTime();
                return dateB - dateA;
              },
            },
            {
              title: "Ver más",
              field: "information",
              render: (rowData: any) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleClickOpen(
                      rowData.information,
                      rowData.status,
                      rowData.id
                    )
                  }
                >
                  Ver Más
                </Button>
              ),
            },
            {
              title: "Estado",
              field: "status",
              render: (rowData: any) => {
                return (
                  <div
                    className={`${Adminstyle["admin__state"]} ${
                      Adminstyle[
                        "admin__state--" +
                          rowData.status.toUpperCase().replace(/ /g, "-")
                      ]
                    }`}
                    style={{ textTransform: "uppercase" }}
                  >
                    <FiberManualRecordIcon></FiberManualRecordIcon>{" "}
                    {rowData.status}
                  </div>
                );
              },
            },
          ]}
          data={registers}
          options={{
            sorting: true,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "No hay trámites creados",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "filas",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
          }}
        />
      </Container>

      <Dialog
        open={openUser}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Información Usuario</DialogTitle>
        <DialogContent>
          {userSummary ? (
            <div>
              <p>
                <span>Nombre: {userSummary["name"]}</span>
              </p>
              <p>
                <span>Apellidos: {userSummary["lastName"]}</span>
              </p>
              <p>
                <span>Celular: {userSummary["cellphone"]}</span>
              </p>
              <p>
                {" "}
                <span>Email: {userSummary["email"]}</span>
              </p>
              <p>
                <span>
                  Documento: {userSummary["type"]} {userSummary["document"]}
                </span>
              </p>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Información Tramite</DialogTitle>

        <DialogContent>
          {summary.map((row: any, key: number) => {
            return row.label !== "Estado" ? (
              <p key={key}>
                <span style={popupSummary}>{row.label}:</span> {row.value}
              </p>
            ) : (
              <FormControl variant="filled" key={key}>
                <InputLabel htmlFor="filled-age-native-simple">
                  Status
                </InputLabel>
                <Select
                  native
                  onChange={changeStatus}
                  value={status.trim().toUpperCase()}
                  inputProps={{
                    name: "status",
                    id: "filled-age-native-simple",
                  }}
                >
                  {optionsStatus.map((option: any, keyOpt: number) => {
                    return (
                      <option key={keyOpt} value={option.trim().toUpperCase()}>
                        {option}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={updateStatus} variant="contained" color="primary">
            Cambiar Estado
          </Button>

          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Admin;
