import React, { useState, Fragment, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../common/Footer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../App";
import { Alert } from "@material-ui/lab";
import logo from "../../images/logo-shield.svg";
import notariaService from "../../services/index";
import { GET_USER } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
interface ChildComponentProps extends RouteComponentProps<any> {
  history: History;
  /* other props for ChildComponent */
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

interface errorObject {
  type: any;
  message: string;
}

const SignIn: React.SFC<ChildComponentProps> = ({ location, history }) => {
  const dispatch = useDispatch();
  const dialogRef = React.createRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrors] = useState<errorObject>({
    type: undefined,
    message: "",
  });
  const [open, setOpen] = React.useState(false);
  const [errorModal, setErrorModal] = useState<errorObject>({
    type: undefined,
    message: "",
  });

  const user = useSelector((state: any) => state.servicesSelected.user);

  useEffect(() => {
    const values: any = queryString.parse(location.search);
    if (values && values.type) {
      setErrors({ type: values.type, message: values.message });
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.error) {
        setErrors({
          type: "error",
          message: "Usuario y/o contraseña incorrecta ",
        });
      } else {
        Auth.setLoggedIn(true);
        history.push("/");
      }
    }
  }, [user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmail("");
    setOpen(false);
  };
  const Auth = useContext(AuthContext);
  const handleForm = (e: any) => {
    e.preventDefault();
    dispatch(GET_USER({ email, password }));
  };

  //to recover account
  const recoverAccount = () => {
    notariaService
      .recoverPassword(email)
      .then((res: any) => {
        // Password reset email sent.
        setErrorModal({
          type: "success",
          message: "Correo enviado exitosamente",
        });
      })
      .catch((e: any) => {
        // Error occurred. Inspect error.code.
        setErrorModal({ type: "error", message: e.message });
      });
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img src={logo} alt="Logo Notaria 13" width="120px" />
          </div>
          <Typography component="h1" align="center" variant="h5">
            Ingreso Plataforma Notaria 13 de Medellín
          </Typography>
          {error.type && error.message !== "" ? (
            <Alert severity={error.type}>{error.message}</Alert>
          ) : null}
          <form
            className={classes.form}
            onSubmit={(e) => handleForm(e)}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              label="Correo Electrónico"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Ingresar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={handleClickOpen} variant="body2">
                  ¿Olvido la contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/registrarse" variant="body2">
                  {"¿No tiene cuenta? Registrarse"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Footer />
        </Box>
      </Container>
      <Dialog
        ref={dialogRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"¿Olvido la contraseña?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorModal.type && errorModal.message !== "" ? (
              <Alert severity={errorModal.type}>{errorModal.message}</Alert>
            ) : null}
            Ingrese el correo electronico registrado:
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="emailRecovery"
              onChange={(e) => setEmail(e.target.value)}
              label="Correo Electrónico"
              name="emailRecovery"
              autoComplete="email"
              autoFocus
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={recoverAccount} color="primary" autoFocus>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withRouter(SignIn);
