import React, { Fragment } from "react";
import ButtonAppBar from "./ButtonAppBar/ButtonAppBar";
import { Box, Fab, Container } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Footer from "../common/Footer";

import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "fixed",
      width: "60px",
      height: "60px",
      bottom: "40px",
      right: "40px",
      backgroundColor: "#25d366",
      color: "#FFF",
      borderRadius: "50px",
    },
    fabGreen: {
      color: theme.palette.common.white,
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[600],
      },
    },
  })
);

interface Props {
  children: any;
}
export default function Layout({ children }: Props) {
  const classes = useStyles();

  return (
    <Fragment>
      <ButtonAppBar></ButtonAppBar>

      {children}
      <Box mt={8}>
        <a
          href="http://api.whatsapp.com/send?phone=573216572167&text=Bienvenido a la Notaria 13 de Medellin, ¿En que podemos ayudarte?"
          target="_blank"
          rel="noopener noreferrer"
          title="Share on whatsapp"
        >
          <Fab color="primary" className={classes.fab} aria-label="whastapp">
            <WhatsAppIcon />
          </Fab>
        </a>

        <Footer />
      </Box>
    </Fragment>
  );
}
