import React, { forwardRef, RefObject, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import MaterialTable, {
  Action,
  MaterialTableProps,
  Icons,
} from "material-table";
import * as firebase from "firebase";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Refresh from "@material-ui/icons/Refresh";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CreateIcon from "@material-ui/icons/Create";
import DateFnsAdapter from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import notariaService from "../../services/index";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const History = () => {
  const [registers, setRegisters] = useState([]);
  const [currentProcess, setCurrentProcess] = useState({});
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(true);

  //this will show all the process that a user has in a table and the status
  const dateFns = new DateFnsAdapter();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (data: any) => {
    let dataSummary: any[] = [];
    Object.keys(data).map(function (key, value) {
      dataSummary.push(data[key]);
      console.log(data[key]);
    });
    setSummary(dataSummary as any);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    notariaService
      .getUserHistory()
      .then((querySnapshot: any) => {
        let tableData: any = [];

        querySnapshot.forEach((doc: any) => {
          tableData.push({
            type: doc.data().type,
            date: dateFns.format(doc.data().date.toDate(), "MM/dd/yyyy"),
            information: doc.data().information,
            status: doc.data().status ?? "En proceso",
          });
        });
        setRegisters(tableData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  var popupSummary = {
    fontWeight: "bold" as "bold",
  };
  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <h1>Historial de Tramites</h1>
        <MaterialTable
          icons={tableIcons}
          title={""}
          isLoading={loading}
          columns={[
            { title: "Tipo de Tramite", field: "type" },
            {
              title: "Fecha",
              field: "date",
              defaultSort: "asc",
              customSort: (a, b) => {
                let dateB = new Date(b.date).getTime();
                let dateA = new Date(a.date).getTime();
                return dateB - dateA;
              },
            },
            {
              title: "Ver más",
              field: "information",
              render: (rowData: any) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClickOpen(rowData.information)}
                >
                  Ver Más {rowData.id}
                </Button>
              ),
            },
            { title: "Estado", field: "status" },
          ]}
          data={registers}
          options={{
            sorting: true,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "No hay trámites creados",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "filas",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
          }}
        />
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Informacion Tramite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {summary.map((row: any) => {
              return (
                <p>
                  <span style={popupSummary}>{row.label}:</span>
                  {Array.isArray(row.value)
                    ? row.value.map((row: any) => {
                        return row + " - ";
                      })
                    : row.value}
                </p>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default History;
