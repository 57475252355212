import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepContainer from "./StepContainer/StepContainer";
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase";
import { SET_ACTIVE_STEP } from "../../../store/actions/index";
import DateFnsAdapter from "@date-io/date-fns";
import { find } from "lodash";
import NotariaService from "../../../services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const StepProcess = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<string[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const dateFns = new DateFnsAdapter();

  const configuration = useSelector((state: any) => {
    return state.servicesSelected.serviceInfo;
  });

  const serviceName = useSelector((state: any) => {
    return state.servicesSelected.service;
  });

  const stepName = useSelector((state: any) => state.servicesSelected.service);
  useEffect(() => {
    //Set the current configuration into the state
    if (configuration) {
      // setNextDisabled(true);
      //check if the process is active
      setIsActive(configuration.active);
      if (configuration.sections) {
        let processSteps: string[] = [];
        configuration.sections.forEach((section: any) => {
          processSteps.push(section.stepName);
        });
        setSteps(processSteps);
      }
    }
    //get the steps
  }, [activeStep]);

  const handleNext = () => {
    setNextDisabled(true);
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    dispatch(SET_ACTIVE_STEP(nextStep));

    //process each section for summarize
    const processSection = (SectionForm: any): any => {
      let summarizeSection: any = {};
      Object.keys(SectionForm).forEach(function (key, value) {
        if (
          SectionForm[key].type !== "HTML" &&
          SectionForm[key].value !== "" &&
          SectionForm[key].value !== null
        ) {
          let label = SectionForm[key].summaryLabel
            ? SectionForm[key].summaryLabel
            : SectionForm[key].label;
          if (SectionForm[key].type === "Checkbox") {
            summarizeSection[key] = {
              label: label,
              value: SectionForm[key].value ? "Si" : "No",
            };
          } else if (SectionForm[key].type === "DatePicker") {
            const dateSelected = new Date(SectionForm[key].value);
            summarizeSection[key] = {
              label: label,
              value: dateSelected.toString(),
            };
          } else {
            summarizeSection[key] = {
              label: label,
              value: SectionForm[key].value,
            };
          }
        }
      });
      return summarizeSection;
    };

    //If is the last step save the information and send the email to users
    if (activeStep === configuration.steps - 1) {
      const db = firebase.firestore();
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          let summarizeOrder: any = {};
          //Summarize the data in the right way

          Object.keys(configuration.sections).map(function (key: any, value) {
            if (!configuration.sections[key].switchFormByPreviousStep) {
              let SectionForm = configuration.sections[key].form;
              summarizeOrder = {
                ...summarizeOrder,
                ...processSection(SectionForm),
              };
            } else {
              if (key > 0) {
                let valueOption =
                  configuration.sections[key - 1].form[
                    configuration.sections[key].switchFormByPreviousStep
                  ].value;
                let options =
                  configuration.sections[key - 1].form[
                    configuration.sections[key].switchFormByPreviousStep
                  ].options;
                let selectedOption = find(options, function (o) {
                  return o.value === valueOption;
                });
                let SectionForm: any =
                  configuration.sections[key].optionsForms[selectedOption.id];
                summarizeOrder = {
                  ...summarizeOrder,
                  ...processSection(SectionForm),
                };
              }
            }
          });

          // User logged in already or has just logged in.

          db.collection("services")
            .add({
              user: user.uid,
              date: dateFns.date(),
              type: stepName,
              status: "creada",
              information: summarizeOrder,
            })
            .then((response) => {
              //Send email to the Notary with the order
              fetch(
                `https://us-central1-notaria-13.cloudfunctions.net/orderCreated/?key=${response.id}`
              ).then(() => {
                console.log("Process Send");
              });
              //
            })
            .catch((error) => {});
          //67Igx5u7CohXYNNW4sW2fA7mMcv1
          //wxDX9GpIENVQD30pz8jOPMj4YRM2 --registro civil
          // sWzqT7bxUdZSX6NqlB51jjdr2F52 -- protocolo
          switch (stepName) {
            case "SOLICITUD DE COPIA(S) DE ESRITURAS":
              NotariaService.sendPushWebNotification(
                "sWzqT7bxUdZSX6NqlB51jjdr2F52"
              );
              break;
            case "SOLICITUD DE ELABORACIÓN DE ESCRITURAS":
              NotariaService.sendPushWebNotification(
                "sWzqT7bxUdZSX6NqlB51jjdr2F52"
              );
              break;
            case "SOLICITUD DE COPIA (S) AUTENTICA DE REGISTRO CIVIL":
              NotariaService.sendPushWebNotification(
                "wxDX9GpIENVQD30pz8jOPMj4YRM2"
              );
              break;
            default:
              NotariaService.sendPushWebNotification(
                "67Igx5u7CohXYNNW4sW2fA7mMcv1"
              );
              break;
          }
          NotariaService.sendPushWebNotification(
            "67Igx5u7CohXYNNW4sW2fA7mMcv1"
          );
        } else {
          // User not logged in or has just logged out.
        }
      });
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
    dispatch(SET_ACTIVE_STEP(prevStep));
  };

  //This method will call from container to enable the next buttons if this validation is require
  const validateStep = (active: boolean) => {
    if (configuration.sections[activeStep].validateForm) {
      setNextDisabled(!active);
    } else {
      setNextDisabled(false);
    }
  };

  return (
    <Fragment>
      {isActive ? (
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                {serviceName !== "SOLICITUD DE ELABORACIÓN DE ESCRITURAS" ? (
                  <Typography className={classes.instructions}>
                    Muchas gracias! Su orden fue procesada y pronto estaremos en
                    contacto brindando información de la cuenta a consignar y
                    envío de su solicitud{" "}
                  </Typography>
                ) : (
                  <Typography className={classes.instructions}>
                    Muchas gracias! la radicación de su trámite escriturario fue
                    procesada, pronto un protocolista de nuestra notaría estará
                    en contacto con usted, brindándole información sobre
                    preliquidacion de costos del proceso de escrituración y
                    citación para firma de la escritura.{" "}
                  </Typography>
                )}
              </div>
            ) : (
              <div>
                <StepContainer validateStep={validateStep}></StepContainer>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Atras
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={nextDisabled}
                  >
                    {activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <h1>Por el momento este trámite no esta disponible por este medio.</h1>
      )}
    </Fragment>
  );
};

export default StepProcess;
