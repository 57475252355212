import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Footer from "../common/Footer";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { AuthContext } from "../../App";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { RouteComponentProps } from "react-router-dom";
import { History } from "history";
import logo from "../../images/logo-shield.svg";
import NotariaService from "../../services/index";
import { validate } from "@material-ui/pickers";
import ReCAPTCHA from "react-google-recaptcha";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import Habeas from "./HabeasData";
import { SET_USER } from "../../store/actions/index";

interface ChildComponentProps extends RouteComponentProps<any> {
  history: History;
  /* other props for ChildComponent */
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      margin: theme.spacing(0),
      fullWidth: true,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const SignUp: React.SFC<ChildComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const Auth = useContext(AuthContext);
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const recaptchaRef: any = React.createRef();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const [username, setUsername] = useState<{
    firstName: string;
    lastName: string;
  }>({
    firstName: "",
    lastName: "",
  });

  const [cellphone, setCellphone] = useState("");
  const [error, setErrors] = useState("");
  const [document, setDocument] = useState<{
    documentNumber: string | number;
    documentType: string;
  }>({
    documentNumber: "",
    documentType: "",
  });

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose2 = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  interface validates {
    field: string | number;
    required: boolean;
    type: string;
    minLength?: number;
    maxLength?: number;
  }

  const validate = (fields: validates) => {
    let valid: boolean = true;
    if (fields.type === "string" && typeof fields.field === "string") {
      if (
        fields.field.length === 0 ||
        (fields.minLength && fields.minLength > fields.field.length) ||
        (fields.maxLength && fields.maxLength > fields.field.length)
      ) {
        valid = false;
      }
    } else if (typeof +fields.field === "number") {
      if (isNaN(+fields.field)) {
        valid = false;
      }
    }

    return valid;
  };

  const handleForm = (e: any) => {
    e.preventDefault();
    const recaptchaValue: any = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setErrors("Verifica que no eres un Robot :-)");
      return;
    }
    if (confirmPassword === password) {
      if (
        validate({
          field: username.firstName,
          type: "string",
          required: true,
          minLength: 3,
        }) &&
        validate({
          field: username.lastName,
          type: "string",
          required: true,
          minLength: 3,
        }) &&
        validate({
          field: password,
          type: "string",
          required: true,
          minLength: 4,
        }) &&
        validate({
          field: document.documentNumber,
          type: "number",
          required: true,
          minLength: 2,
        }) &&
        validate({
          field: document.documentType,
          type: "string",
          required: true,
        }) &&
        validate({ field: cellphone, type: "string", required: true })
      ) {
        NotariaService.registerUser(
          email,
          password,
          username,
          cellphone,
          document
        )
          .then((response: any) => {
            if (response.user) Auth.setLoggedIn(true);
            dispatch(
              SET_USER({
                email: email,
                name: username.firstName,
                lastName: username.lastName,
                cellphone: cellphone,
                type: document.documentType,
                document: document.documentNumber,
                uid: response.user.uid,
              })
            );
            history.push("/");
          })
          .catch((error: any) => {
            //setErrors(e.message);
            var errorCode = error.code;
            var errorMessage = error.message;

            if (errorCode == "auth/weak-password") {
              setErrors("La contraseña debe tener más de 6 caracteres");
            } else {
              setErrors(errorMessage);
            }
          });
      } else {
        setErrors("Error, los  campos con * son obligatorios");
      }
    } else {
      setErrors("Las contraseñas no coinciden");
    }
  };

  const handleChangeName = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof username;
    setUsername({
      ...username,
      [name]: event.target.value,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof document;
    setDocument({
      ...document,
      [name]: event.target.value,
    });
  };
  function onChange(value: any) {
    console.log("Captcha value:", value);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <img src={logo} alt="logo Notaria 13" width="120px" />
        </div>
        <Typography component="h1" variant="h5">
          Registro
        </Typography>
        {error ? <Alert severity="error">{error}</Alert> : null}

        <form
          className={classes.form}
          onSubmit={(e) => handleForm(e)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                onChange={handleChangeName}
                id="firstName"
                label="Nombres"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                onChange={handleChangeName}
                label="Apellidos"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Correo Electronico"
                name="email"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
                fullWidth={true}
              >
                <InputLabel htmlFor="outlined-documentType-native-simple">
                  Tipo Documento
                </InputLabel>
                <Select
                  native
                  label="Tipo Documento"
                  onChange={handleChange}
                  inputProps={{
                    name: "documentType",
                    id: "outlined-documentType-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={"CC"}>CC</option>
                  <option value={"CE"}>CE</option>
                  <option value={"TI"}>TI</option>
                  <option value={"Pasaporte"}>Pasaporte</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                id="documentNumber"
                label="Documento"
                name="documentNumber"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="cellphone"
                variant="outlined"
                type="tel"
                onChange={(e) => setCellphone(e.target.value)}
                required
                fullWidth
                id="cellphone"
                label="Celular"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Contraseña"
                type="password"
                id="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={(e) => setConfirmPassword(e.target.value)}
                name="confirmPassword"
                label="Confirmar Contraseña"
                type="password"
                id="confirmPassword"
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleClickOpen("paper")}>
                Politicas de Datos
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <ReCAPTCHA
                  sitekey={"6Lcgj6MZAAAAACf-L-RsO1HxMacTLdoGVYAJoGXE"}
                  onChange={onChange}
                  ref={recaptchaRef}
                >
                  {" "}
                </ReCAPTCHA>
              </Box>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrarse
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/ingresar" variant="body2">
                ¿Ya tienes una cuenta? Ingresar
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Footer />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose2}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Política de manejo de datos
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Habeas></Habeas>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default withRouter(SignUp);
