import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { AuthContext } from "../../../App";
import { withRouter } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CreateIcon from "@material-ui/icons/Create";
import HistoryIcon from "@material-ui/icons/History";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotariaService from "../../../services/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import * as firebase from "firebase";
import { MonetizationOnRounded } from "@material-ui/icons";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

type Anchor = "top" | "left" | "bottom" | "right";

function ButtonAppBar(props: any) {
  React.useEffect(() => {
    if (user && user.rol && user.rol === "admin") {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      }
      // Let's check whether notification permissions have alredy been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
      } else if (Notification.permission === "default") {
        setOpen(true);
      }
    }
  }, []);

  const messaging = firebase.messaging();
  messaging.onMessage(function (payload) {
    console.log("onMessage", payload);
    setOpenSnack(true);
  });

  const dispatch = useDispatch();
  const classes = useStyles();
  const Auth = useContext(AuthContext);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const activeNotification = () => {
    messaging
      .requestPermission()
      .then(function () {
        return messaging.getToken();
      })
      .then((token) => {
        //Store the token
        NotariaService.saveUserToken(user.uid, token).then(() => {
          console.log("saved");
        });
        setOpen(false);
      })

      .catch(function (error) {
        console.error(error);
        console.error("error occurred");
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector((state: any) => {
    return state.servicesSelected.user;
  });

  const longOut = () => {
    //sessionStorage.removeItem(`firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`);
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        Auth.setLoggedIn(false);

        props.history.push("/ingresar");

        //
      })
      .catch(function (error) {
        // An error happened.
        console.log("error");
      });
  };
  const nextPath = (path: string) => {
    props.history.push(path);
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {user && !user.rol && user.rol !== "admin" ? (
          <ListItem button onClick={() => nextPath("/")}>
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary={"Crear Tramites"} />
          </ListItem>
        ) : null}

        <ListItem button onClick={() => nextPath("/history")}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary={"Mis Tramites"} />
        </ListItem>
        <ListItem button onClick={() => nextPath("/profile")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={"Perfil"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => longOut()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Salir"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Plataforma Notaria 13 Medellín
          </Typography>

          {user && !user.rol && user.rol !== "admin" ? (
            <Button
              type="button"
              onClick={() => nextPath("/")}
              color="inherit"
              startIcon={<CreateIcon />}
            >
              Crear Tramites
            </Button>
          ) : null}

          {user && (!user.rol || user.rol !== "admin") ? (
            <Button
              type="button"
              onClick={() => nextPath("/history")}
              color="inherit"
              startIcon={<HistoryIcon />}
            >
              Mis Tramites
            </Button>
          ) : null}
          <Button
            type="button"
            onClick={() =>
              window.open(
                "https://www.notaria13medellin.co/tarifas-notariales/",
                "_blank"
              )
            }
            color="inherit"
            startIcon={<MonetizationOnRounded />}
          >
            Tarifas
          </Button>
          <Button
            type="button"
            onClick={() => longOut()}
            color="inherit"
            startIcon={<ExitToAppIcon />}
          >
            Salir
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea activar las notificaciones de Notaria 13 Medellin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={activeNotification} color="primary">
            Activar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="info">
          Se creo una solicitud, refresca la pagina para revisar
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withRouter(ButtonAppBar);
