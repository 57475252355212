import React, { Fragment, useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  Grid,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  Link,
  Chip,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { SERVICE_DATA } from "../../../../store/actions/index";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { find, findIndex } from "lodash";
import Input from "@material-ui/core/Input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "45ch",
    },
    checkbox: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "45ch",
      flexWrap: "wrap",
      display: "flex",
      flexDirection: "initial",
      marginTop: "16px",
      marginBottom: "8px",
    },
    label: {
      width: "38ch",
    },
    select: {
      width: "100%",
      minHeight: "30px",
    },
    formControl: {
      margin: theme.spacing(1),
      marginTop: "16px",
      width: "45ch",
    },

    summaryLabel: {
      fontWeight: "bolder",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      width: "100%",
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StepContainer = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  // The first commit of Material-UI
  const [formData, setFormData] = React.useState<any | null>(null);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  //this for switchFormByPreviousStep
  const [selectedOption, setSelectedOption] = React.useState<number | null>(
    null
  );
  const configuration = useSelector((state: any) => {
    return state.servicesSelected.serviceInfo;
  });
  const activeStep = useSelector(
    (state: any) => state.servicesSelected.activeStep
  );

  useEffect(() => {
    setFormData(configuration);
    setCurrentStep(activeStep);
  }, [activeStep]);

  interface Foo {
    [key: string]: number;
  }
  useEffect(() => {
    if (formData) {
      //if the step depend of options of other step
      if (formData.sections[activeStep].switchFormByPreviousStep) {
        let valueOption =
          formData.sections[activeStep - 1].form[
            formData.sections[activeStep].switchFormByPreviousStep
          ].value;
        let options =
          formData.sections[activeStep - 1].form[
            formData.sections[activeStep].switchFormByPreviousStep
          ].options;

        let selectedOption = find(options, function (o) {
          return o.value === valueOption;
        });
        setSelectedOption(selectedOption.id);
      } else {
        setSelectedOption(null);
      }

      let valid = true;
      //Check if all required field has data valid
      if (!formData.sections[activeStep].switchFormByPreviousStep) {
        let groupCounters: Foo = {};
        //Check for the current group

        Object.keys(formData.sections[activeStep].form).map((key) => {
          if (
            formData.sections[activeStep].form[key].required &&
            !formData.sections[activeStep].form[key].disabled
          ) {
            if (
              formData.sections[activeStep].form[key].value === "" ||
              formData.sections[activeStep].form[key].value === null ||
              formData.sections[activeStep].form[key].value === false ||
              formData.sections[activeStep].form[key].value.length === 0
            ) {
              valid = false;
            }
          } else if (
            formData.sections[activeStep].form[key].group &&
            formData.sections[activeStep].Groups[
              formData.sections[activeStep].form[key].group
            ].required
          ) {
            console.log("group required");
            if (
              formData.sections[activeStep].form[key].value === "" ||
              formData.sections[activeStep].form[key].value === null ||
              formData.sections[activeStep].form[key].value === false ||
              formData.sections[activeStep].form[key].length === 0
            ) {
              //valid = false;
              //if the element doesn't exist create
              const groupName = formData.sections[activeStep].form[key].group;
              if (!groupCounters[groupName]) {
                groupCounters[groupName] = 1;
              } else {
                //if the element exist add ++
                groupCounters[groupName] = groupCounters[groupName] + 1;
              }
            }
          }
        });
        //test

        //Evaluate group
        Object.keys(groupCounters).forEach(function (group) {
          if (
            groupCounters[group] >
            formData.sections[activeStep].Groups[group].minValid
          ) {
            valid = false;
          }
        });

        /************************************* */
        //WE HAVE TO DEFINE THE SAME THING FOR THE OTHER CASES
      } else if (selectedOption) {
        //the form to validate are inside the
        Object.keys(
          formData.sections[activeStep].optionsForms[selectedOption]
        ).map((key) => {
          if (
            formData.sections[activeStep].optionsForms[selectedOption][key]
              .required
          ) {
            if (
              formData.sections[activeStep].optionsForms[selectedOption][key]
                .value === "" ||
              formData.sections[activeStep].optionsForms[selectedOption][key]
                .value === null
            ) {
              valid = false;
            }
          }
        });
      }
      props.validateStep(valid);
    }
  }, [currentStep, formData, selectedOption]);

  const handleChange = (event: any) => {
    const config = cloneDeep(formData);
    let _tempFormData: any;
    if (!config.sections[activeStep].switchFormByPreviousStep) {
      _tempFormData = config.sections[activeStep].form;
    } else {
      if (selectedOption) {
        _tempFormData =
          config.sections[activeStep].optionsForms[selectedOption];
      }
    }

    if (event) {
      if (_tempFormData[event.target.id]["type"] === "Checkbox") {
        _tempFormData[event.target.id]["value"] = event.target.checked;
      } else if (_tempFormData[event.target.id]["type"] === "DatePicker") {
        //_tempFormData[event.target.id]["value"] = event.target.value.toString();
        _tempFormData[event.target.id]["value"] = event.target.value
          ? event.target.value.toString()
          : null;
      } else {
        _tempFormData[event.target.id]["value"] = event.target.value;
      }
      //check if the element has a dependency
      if (_tempFormData[event.target.id]["depends"]) {
        //IF CHECKBOX IS SELECTED OF THE SELECT HAS MORE THAT ONE ITEM SELECTED FOR MULTIPLE
        if (
          _tempFormData[event.target.id]["value"] === true ||
          _tempFormData[event.target.id]["value"].length > 0
        ) {
          //Check if is an array of elements
          if (
            Array.isArray(_tempFormData[event.target.id]["depends"].element)
          ) {
            for (
              let i = 0;
              i < _tempFormData[event.target.id]["depends"].element.length;
              i++
            ) {
              let elementId =
                _tempFormData[event.target.id]["depends"].element[i];
              //If the depends element is a selector and has multiple elements
              if (
                _tempFormData[event.target.id]["type"] === "Select" &&
                _tempFormData[event.target.id]["multiple"] === true
              ) {
                //check the selected values

                const index = findIndex(
                  _tempFormData[event.target.id]["value"],
                  (o: string) => {
                    return o === (_tempFormData[elementId]["label"] as string);
                  }
                );

                if (index > -1) {
                  _tempFormData[elementId]["disabled"] = false;
                  if (_tempFormData[elementId]["visible"] !== undefined) {
                    _tempFormData[elementId]["visible"] = true;
                  }
                } else {
                  _tempFormData[elementId]["disabled"] = true;
                  if (_tempFormData[elementId]["type"] !== "HTML") {
                    _tempFormData[elementId]["value"] = "";
                  }
                  if (_tempFormData[elementId]["visible"] !== undefined) {
                    _tempFormData[elementId]["visible"] = false;
                  }
                }

                //Check for the other elements not select to disabled those
              } else {
                _tempFormData[
                  _tempFormData[event.target.id]["depends"].element[i]
                ]["disabled"] = false;
                if (
                  _tempFormData[
                    _tempFormData[event.target.id]["depends"].element[i]
                  ]["visible"] !== undefined
                ) {
                  _tempFormData[
                    _tempFormData[event.target.id]["depends"].element[i]
                  ]["visible"] = true;
                }
              }
            }
          } else {
            _tempFormData[_tempFormData[event.target.id]["depends"].element][
              "disabled"
            ] = false;
            //only if has the visible property
            if (
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "visible"
              ] !== undefined
            ) {
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "visible"
              ] = true;
            }
          }
        } else {
          if (
            Array.isArray(_tempFormData[event.target.id]["depends"].element)
          ) {
            console.log("is an array");
            for (
              let i = 0;
              i < _tempFormData[event.target.id]["depends"].element.length;
              i++
            ) {
              let elementId =
                _tempFormData[event.target.id]["depends"].element[i];
              //check the selected values
              _tempFormData[elementId]["disabled"] = true;
              if (_tempFormData[elementId]["visible"] !== undefined) {
                _tempFormData[elementId]["visible"] = false;
              }
              if (_tempFormData[elementId]["type"] !== "HTML") {
                _tempFormData[elementId]["value"] = "";
              }
            }
          } else {
            _tempFormData[_tempFormData[event.target.id]["depends"].element][
              "disabled"
            ] = true;

            if (
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "type"
              ] !== "HTML"
            ) {
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "value"
              ] = "";
            }

            if (
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "visible"
              ] !== undefined
            ) {
              _tempFormData[_tempFormData[event.target.id]["depends"].element][
                "visible"
              ] = false;
            }
          }
        }
      }
      setFormData(config);
      //configuration.sections[activeStep].form = _tempFormData;
      dispatch(SERVICE_DATA(config));
    }
  };
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const createForm = (FormElement: any, id: string, key: number) => {
    switch (FormElement.type) {
      case "TextField":
        return (
          <FormControl key={key}>
            <TextField
              style={
                FormElement.visible === true ||
                FormElement.visible === undefined
                  ? { display: "inline-flex" }
                  : { display: "none" }
              }
              disabled={FormElement.disabled ? true : false}
              required={FormElement.required ? true : false}
              multiline={FormElement.multiline ? true : false}
              id={id}
              value={FormElement.value}
              onChange={handleChange}
              label={FormElement.label}
              className={classes.textField}
              rows={FormElement.rows}
            ></TextField>
            {FormElement.html ? (
              <div
                style={
                  FormElement.visible === true ||
                  FormElement.visible === undefined
                    ? { display: "inline-flex" }
                    : { display: "none" }
                }
                key={key}
                dangerouslySetInnerHTML={{
                  __html: FormElement.html,
                }}
              ></div>
            ) : null}
          </FormControl>
        );
      case "Input":
        return (
          <FormControl
            fullWidth
            key={key}
            style={
              FormElement.visible === true || FormElement.visible === undefined
                ? {
                    display: "inline-flex",
                    width: "45ch",
                    marginLeft: "8px",
                    marginRight: "8px",
                  }
                : { display: "none" }
            }
          >
            <InputLabel htmlFor="standard-adornment-amount">
              {FormElement.preLabel ? FormElement.preLabel : ""}{" "}
              {FormElement.label}
            </InputLabel>
            <Input
              style={
                FormElement.visible === true ||
                FormElement.visible === undefined
                  ? { display: "inline-flex" }
                  : { display: "none" }
              }
              disabled={FormElement.disabled ? true : false}
              required={FormElement.required ? true : false}
              multiline={FormElement.multiline ? true : false}
              id={id}
              value={FormElement.value}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        );

      case "Checkbox":
        return (
          <FormControlLabel
            classes={{
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
            key={key}
            style={
              FormElement.visible === true || FormElement.visible === undefined
                ? { display: "inline-flex" }
                : { display: "none" }
            }
            className={classes.checkbox}
            control={
              <Checkbox
                disabled={FormElement.disabled ? true : false}
                name="checkedB"
                id={id}
                color="primary"
                checked={FormElement.value}
                onChange={handleChange}
              />
            }
            labelPlacement="start"
            label={FormElement.label}
          />
        );
      case "Select":
        return (
          <FormControl
            key={key}
            required
            className={classes.formControl}
            style={
              FormElement.visible === true || FormElement.visible === undefined
                ? { display: "inline-flex" }
                : { display: "none" }
            }
          >
            <InputLabel htmlFor={key.toString()}>
              {FormElement.label}
            </InputLabel>
            <Select
              disabled={FormElement.disabled ? true : false}
              displayEmpty
              value={FormElement.value}
              multiple={FormElement.multiple}
              input={<Input id="select-multiple-chip" />}
              onChange={(
                selected: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                //this change to match with the structure of the other fields
                let event = {
                  target: {
                    id: id,
                    value: selected.target.value,
                  },
                };
                return handleChange(event);
              }}
              inputProps={{
                id: id,
              }}
              renderValue={
                FormElement.multiple
                  ? (selected) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  : () => FormElement.value
              }
              classes={{
                select: classes.select, // class name, e.g. `classes-nesting-label-x`
              }}
            >
              {FormElement.options.map((value: any) => {
                return (
                  <MenuItem
                    value={value.value}
                    key={value.id}
                    style={getStyles(value.value, FormElement.value, theme)}
                  >
                    {" "}
                    {value.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case "DatePicker":
        return (
          <FormControl
            key={key}
            style={
              FormElement.visible === true || FormElement.visible === undefined
                ? { display: "inline-flex" }
                : { display: "none" }
            }
          >
            {" "}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                disabled={FormElement.disabled ? true : false}
                margin="normal"
                label={FormElement.label}
                id={id}
                maskChar="_"
                minDateMessage="Fecha minima no valida"
                mask={FormElement.mask ? FormElement.mask : "__/__/____"}
                disableFuture={true}
                invalidDateMessage={
                  FormElement.errormenssage
                    ? FormElement.errormenssage
                    : "Fecha Invalida"
                }
                cancelLabel="Cancelar"
                okLabel="Aceptar"
                format={FormElement.format ? FormElement.format : "MM/dd/yyyy"}
                value={FormElement.value}
                className={classes.textField}
                onChange={(date: Date | null) => {
                  //this change to match with the structure of the other fields
                  let event = {
                    target: {
                      id: id,
                      value: date,
                    },
                  };
                  return handleChange(event);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        );
      case "HTML":
        return (
          <div
            style={
              FormElement.visible === true || FormElement.visible === undefined
                ? { display: "inline-flex" }
                : { display: "none" }
            }
            key={key}
            dangerouslySetInnerHTML={{
              __html: FormElement.value,
            }}
          ></div>
        );
      case "Radiogroup":
        return (
          <FormControl component="fieldset" key={key}>
            <FormLabel component="legend">{FormElement.label}</FormLabel>
            <RadioGroup
              aria-label={FormElement.label}
              id={id}
              name={FormElement.label}
              value={FormElement.value}
              onChange={handleChange}
            >
              {FormElement.options.map((value: any) => {
                return (
                  <FormControlLabel
                    classes={{
                      label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    value={value.value}
                    key={value.id}
                    control={<Radio id={id} />}
                    label={value.value}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      default:
        return <h1>NO ELEMENT </h1>;
    }
  };

  const summarySectionOptions = (SectionFormOptions: any, key: number) => {
    let valueOption =
      configuration.sections[key - 1].form[
        configuration.sections[key].switchFormByPreviousStep
      ].value;
    console.log(valueOption);
    let options =
      configuration.sections[key - 1].form[
        configuration.sections[key].switchFormByPreviousStep
      ].options;

    let selectedOption = find(options, function (o) {
      return o.value === valueOption;
    });

    return summarySection(
      configuration.sections[key].optionsForms[selectedOption.id]
    );
  };

  const summarySection = (SectionForm: any) => {
    return Object.keys(SectionForm).map(function (key, value) {
      if (
        SectionForm[key].type !== "HTML" &&
        SectionForm[key].value !== "" &&
        SectionForm[key].value !== null
      ) {
        let label = SectionForm[key].summaryLabel
          ? SectionForm[key].summaryLabel
          : SectionForm[key].label;
        if (SectionForm[key].type === "Checkbox") {
          return (
            <p key={key}>
              <span className={classes.summaryLabel}>{label}: </span>{" "}
              {SectionForm[key].value ? "Si" : "No"}{" "}
            </p>
          );
        } else if (SectionForm[key].type === "DatePicker") {
          const dateSelected = new Date(SectionForm[key].value);
          return (
            <p key={key}>
              {" "}
              <span className={classes.summaryLabel}>{label}: </span>{" "}
              {dateSelected.getDate() +
                " / " +
                (dateSelected.getMonth() + 1) +
                " / " +
                dateSelected.getFullYear()}
            </p>
          );
        } else {
          return (
            <p key={key}>
              {" "}
              <span className={classes.summaryLabel}>{label}: </span>{" "}
              {SectionForm[key].value}
            </p>
          );
        }
      }
      return null;
    });
  };

  return (
    <Fragment>
      <div
        dangerouslySetInnerHTML={{
          __html: configuration.sections[currentStep].text,
        }}
      ></div>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <form className={classes.root} noValidate autoComplete="off">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            {formData
              ? !formData.sections[activeStep].switchFormByPreviousStep
                ? Object.keys(formData.sections[activeStep].form).map(function (
                    key,
                    value
                  ) {
                    return createForm(
                      formData.sections[activeStep].form[key],
                      key,
                      value
                    );
                  })
                : selectedOption &&
                  formData.sections[activeStep].optionsForms[selectedOption]
                ? Object.keys(
                    formData.sections[activeStep].optionsForms[selectedOption]
                  ).map(function (key, value) {
                    return createForm(
                      formData.sections[activeStep].optionsForms[
                        selectedOption
                      ][key],
                      key,
                      value
                    );
                  })
                : null
              : null}
          </Grid>
        </form>
        {configuration.sections[currentStep].showSummary
          ? Object.keys(configuration.sections).map(function (key, value) {
              if (!configuration.sections[key].switchFormByPreviousStep) {
                return summarySection(configuration.sections[key].form);
              } else {
                return summarySectionOptions(
                  configuration.sections[key].optionsForms,
                  +key
                );
              }
            })
          : null}
      </Grid>
    </Fragment>
  );
};
export default StepContainer;
