import { call, put, takeEvery } from "redux-saga/effects";

import {
  SERVICE_DATA,
  SERVICE_SELECT,
  SET_ACTIVE_STEP,
  GET_USER,
  SET_USER,
} from "../actions/index";
import notariaService from "../../services/index";

function* getAdminCampaignsSaga(action: any) {
  try {
    const response = yield call(
      notariaService.loginUser,
      action.payload.email,
      action.payload.password
    );
    yield put(SET_USER(response));
  } catch (err) {
    yield put(SET_USER({ error: err }));
  }
}

export default function* notariaSaga() {
  yield takeEvery(GET_USER, getAdminCampaignsSaga);
}
